import { ComputeResults } from "./ComputeResults";
import { ComputeList } from "./ComputeList";
import { Router } from "@/helpers/ClaimsGate/routes/Router";
import { PageHelper } from "@/helpers/ClaimsGate/funnels/PageHelper";
import { UserBlockComputeService } from "@/helpers/ClaimsGate/pages/computes";

/**
 * ComputeProcessor is responsible for executing 'computes' in Claims Gate
 * The class maintains a copy of each available compute and result
 */
class ComputeProcessor {
  computeResults;
  computeList;
  userBlockComputeService;
  router;

  constructor() {
    this.computeResults = new ComputeResults();
    this.computeList = new ComputeList();
    this.router = Router.getInstance();
    this.userBlockComputeService = new UserBlockComputeService();
  }
  /**
   * Returns the Compute Results class used by this Compute Processor
   * @returns
   */
  getComputeResults() {
    return this.computeResults;
  }

  /**
   * Executes an array of computes synchronously and in order of arrival
   * @param  { import("@claimsgate/core-types").Page} page - Question object associated with computes
   * @param  {Array<import("@claimsgate/core-types").Compute>} computes - Array of computes for execution
   * @return { Promise<{result: any, next: any, error: any, exception: any}>} Indicates if all computes were ran successfully
   */
  async runComputes(page, computes, claimId) {
    try {
      // While there is more work to complete
      while (computes.length > 0) {
        // Execute teh next compute
        const compute = computes.shift();
        // Extract the name of compute to execute associated invoke parameters
        let { name, params, id } = compute;

        if (name === "CATCH") {
          return { result: { webhookId: id, isCatchHook: true, computes: computes } };
        }

        if (window.console) window.console.log("Calling run computes!");
        if (window.console) window.console.log(params);

        // Fetch the values for any required parameters
        let parsedParams = {};
        // Parse any required data from the parameters
        if (window.console) window.console.log("params is..", params);
        if (params) {
          for (const [key, value] of Object.entries(params)) {
            if (Array.isArray(value)) {
              await Promise.all(
                value.map(async (element, elementIndex) => {
                  if (typeof element === "object") {
                    // For each {k,v} in the array of objects
                    for (const [arrObjectKey, arrObjectValue] of Object.entries(element)) {
                      if (typeof arrObjectValue === "object" && arrObjectKey !== "as") {
                        if (window.console) window.console.log("array object value is...", arrObjectValue);
                        const { id, type, group, field } = arrObjectValue;
                        if (type && id) {
                          if (group === "user") {
                            params[key][elementIndex][arrObjectKey] = await PageHelper.loadVariable(
                              page,
                              field,
                              claimId
                            );
                          } else {
                            params[key][elementIndex][arrObjectKey] = await PageHelper.loadVariable(page, id, claimId);
                          }
                        }
                      }
                    }
                  }
                })
              );
            } else if (typeof value === "object") {
              const { type, id, group, field } = value;

              if (type && id) {
                if (group === "user") {
                  parsedParams[key] = await PageHelper.loadVariable(page, field, claimId);
                } else {
                  parsedParams[key] = await PageHelper.loadVariable(page, id, claimId);
                }
              }
            }
          }
          if (window.console) window.console.log("Params is...", params);
        }

        // Are we dealing with a HTTP compute
        let httpMethods = ["GET", "POST", "PUT", "PATCH", "DELETE"];
        if (httpMethods.includes(name)) {
          name = name.toLowerCase();
        }

        // Keep this execution at this position until we recieve the response

        // In the mean time - tell form.vue to update the UI
        let error, result, next, exception;

        if (typeof this.computeList[name] === "function") {
          ({ error, result, next, exception } = await this.computeList[name](page, claimId, params));
        } else {
          // If the compute is part of the user block compute service
          // ({ error, result, next, exception } = await this.userBlockComputeService.runCompute(name, page, claimId));
        }

        window.console.log("Sent a compute", name, error, result);

        // If there was a major exception

        if (exception) {
          return { exception: exception };
        }
        // If something went wrong during an execution return the error to be displayed
        if (error) {
          return { result: false, error: error };
        }

        if (result) {
          // Store the result globally in case it needs to be reused
          this.computeResults.setResult(name, result);
        }

        // If there is a new route to be displayed, set it in the router
        if (next) {
          this.router.acceptNextRoute(next);
        }

        if (!result) {
          return { result: false };
        }
      }
      return {
        result: true,
      };
    } catch (e) {
      if (window.console) window.console.log("went bad", e);
      return { exception: e };
    }
  }
}

let _computeProcessor = null;
/**
 * Returns the compute processor singleton
 */
const getComputeProcessor = () => {
  if (!_computeProcessor) {
    _computeProcessor = new ComputeProcessor();
  }
  return _computeProcessor;
};

export { getComputeProcessor, ComputeProcessor };
