import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
import { Block, Page } from "@claimsgate/core-types";
import { PageService } from "@/helpers/ClaimsGate/pages";
import { BaseValidationService } from "../validations";
import { BuilderPropService } from "../../builder/BuilderPropService";
import { PageHelper } from "../../funnels/PageHelper";
import { PageUtility } from "../PageUtility";
import { FormState } from "@/components/claimant/form/BlockFormInstance";
export class RepeatableService {
  pageService: PageService;
  baseValidationService: BaseValidationService;
  builderPropService: BuilderPropService;
  constructor() {
    this.pageService = new PageService();
    this.baseValidationService = new BaseValidationService();
    this.builderPropService = new BuilderPropService();
  }
  /**
   * Looks for the container in the page from given container id, and stores the answers of the containers children.
   * @param { String } claimId
   * @param { Object } page
   * @param { String } containerId
   * @param { Number } currentIteration The current index of the container, whose answers are to be stored
   * @param { Number | undefined } [iterationToLoad] The index of the iteration of the containerAnswers to be loaded. Optional. Will set containers children's answers to undefined if not provided
   * @returns {void}
   */
  storeAnswers(
    claimId: string,
    page: Page,
    currentIteration: number,
    iterationToLoad: number | undefined = undefined,
    storeAnswers = true
  ): void {
    const { blocks } = page;
    const claimDataService = ClaimDataService.getInstance(claimId);
    const variableService = new VariableService();

    const repeatableStoreAs: Array<{ [key: string]: any }> =
      claimDataService.getArtefact(page.repeatableStoreAs.value.id) ?? [];

    window.console.log("[storeRepeatableIterationAnswers] ", currentIteration, iterationToLoad, storeAnswers);
    blocks
      .filter((block: Block) => block.storeAs)
      .forEach((block: Block) => {
        /** @type { Object } */
        const currentIterationAnswers: object = {};

        // If a containerAnswer exists in the claimData, get it, else getArtefact() will return null so initialise containerAnswer as Array

        // Find answers

        // Filter the blocks in this container to only include those which have a 'storeAs' field

        const { storeAs, answer } = block;

        const { property, id: variableId } = storeAs;

        window.console.log("trying to store a value: ", answer, variableId, property);
        if (variableId && answer) {
          const parsedAnswer = variableService.parseValue(answer, property.type);

          // Validate if the given answer is of the correct type
          const isValidValue = variableService.validate(parsedAnswer, property.type);

          window.console.log("is valid, is parsed", parsedAnswer, isValidValue, storeAnswers);
          // If the value is of the correct type, we will set it to be stored
          // in Firebase

          // ? Should we be checking here for required fields? If so, we'd need to set state, and prevent moving to next iteration, but should allow moving to previous.

          if (isValidValue) {
            currentIterationAnswers[property.field] = parsedAnswer;
          } else {
            //! Need to break here and set block state to false
          }
        }

        // Blank answers, or load in an iterations answers

        window.console.log(
          "[storeRepeatableIterationAnswers] iterationToLoad, containerAnswer[iterationToLoad]",
          iterationToLoad,
          repeatableStoreAs[iterationToLoad]
        );
        if (iterationToLoad === undefined) {
          block.answer = "";
        } else {
          if (!repeatableStoreAs[iterationToLoad]) {
            block.answer = ""; // ? Not sure if all input components initalize answer to a 'string'
          } else {
            block.answer = repeatableStoreAs[iterationToLoad][property.field];
            // block.answer = PageUtility.loadVariable(page, variableId, claimId, property.field, iterationToLoad);
            console.log("Block answer loaded as", block.answer);
          }
        }

        window.console.log(
          "[containerAnswer]",
          repeatableStoreAs,
          blocks.filter((b: Block) => b.storeAs)
        );

        //  Set the answers of this iteration

        if (storeAnswers) {
          window.console.log("[storeRepeatableIterationAnswers] storing answer as", currentIterationAnswers);

          repeatableStoreAs[currentIteration] = currentIterationAnswers;
          claimDataService.setArtefact(page.repeatableStoreAs.value.id, repeatableStoreAs);
        }
      });
  }

  /**
   *
   * @param {string} claimId
   * @param {*} page
   * @param {string} containerId
   * @param {number} iterationToDelete
   */
  async deleteIteration(claimId: string, page: any, iterationToDelete: number) {
    const storeAsId = page?.repeatableStoreAs?.value?.id;
    const claimDataService = ClaimDataService.getInstance(claimId);
    const repeatableAnswer: Array<any> = claimDataService.getArtefact(storeAsId);

    console.log("repeatAnswer before delete", JSON.parse(JSON.stringify(repeatableAnswer)));
    if (repeatableAnswer?.length > 0) {
      repeatableAnswer.splice(iterationToDelete, 1);
    } else {
      // somethings wrong, no answers?
    }
    console.log("setting after delete", repeatableAnswer);
    claimDataService.setArtefact(storeAsId, repeatableAnswer);
  }

  loadIteration(claimId: string, page: Page, iterationToLoad) {
    const { blocks } = page;
    const claimDataService = ClaimDataService.getInstance(claimId);

    const repeatableStoreAs: Array<{ [key: string]: any }> =
      claimDataService.getArtefact(page.repeatableStoreAs.value.id) ?? [];

    window.console.log("[loadIteration] ", iterationToLoad, repeatableStoreAs);
    blocks.forEach((container) => {
      // If a containerAnswer exists in the claimData, get it, else getArtefact() will return null so initialise containerAnswer as Array

      // Find answers
      container.rows.forEach((row) => {
        row.cols.forEach((col) => {
          // Filter the blocks in this container to only include those which have a 'storeAs' field
          const filteredBlocks = col.blocks.filter((block) => block.storeAs);
          console.log("filtered blocks to store", filteredBlocks);

          for (const block of filteredBlocks) {
            const { storeAs } = block;

            const { property } = storeAs;

            window.console.log("loading answers", iterationToLoad, repeatableStoreAs[iterationToLoad]);
            if (iterationToLoad === undefined) {
              block.answer = "";
            } else {
              if (!repeatableStoreAs[iterationToLoad]) {
                block.answer = "";
              } else {
                block.answer = repeatableStoreAs[iterationToLoad][property.field];
              }
            }
          }
          window.console.log("[containerAnswer]", repeatableStoreAs, filteredBlocks);
        });
      });
    });
  }

  /**
   * Validate the iteration, saves the answers, and sets the iteration to load, loading in answers if available
   * @param page the forms page
   * @param claimId the current claim id
   * @param $store the vue $store object
   * @param refresh whether the view should be refreshed to display different iteration answers
   * @returns { Promise<boolean>}
   */
  async nextPage(page: Page, claimId: string, $store: any, formState: FormState, refresh = true): Promise<boolean> {
    const loaderName = "nextRepeatablePage";

    const { currentIteration } = page;

    let isPageBaseValid = false;

    const allAnswersEmpty = this.allAnswersEmpty(page);

    // Could be a page submit, ignore validation if all answers are empty
    if (currentIteration > 0 && !refresh) {
      if (!allAnswersEmpty) {
        isPageBaseValid = await this.baseValidationService.runBaseValidations(page, formState);
      } else {
        isPageBaseValid = true;
      }
    } else {
      // Validate the current container (if it fails we have found our answer)
      // isPageBaseValid = await PageHelper.runBaseValidation(page);
      isPageBaseValid = await this.baseValidationService.runBaseValidations(page, formState);
    }

    if (!isPageBaseValid) {
      $store.dispatch("form/setLoader", { name: loaderName, value: false });
      return false;
    }

    // If undefined current iterations answers will remain ( as is it a page submit)
    let iterationToLoad: number;
    if (refresh) {
      iterationToLoad = currentIteration + 1;
      page.currentIteration = iterationToLoad;
    }

    try {
      // if all answers empty, we dont want to store the iteration
      if (!allAnswersEmpty) {
        this.storeAnswers(claimId, page, currentIteration, iterationToLoad);
      }
    } catch (exception) {
      $store.dispatch("form/setLoader", { name: loaderName, value: false });
      window.console.log("[[repeatableContainerResults] returning false as page base invalid");
      return false;
    }

    if (refresh) {
      // Refresh the display to ensure block show the new iterations answers
      $store.dispatch("events/fire", {
        name: "refreshPage",
      });

      await this.pageService.resetValidation(page);
    }
    $store.dispatch("form/setLoader", { name: loaderName, value: false });
    return true;
  }

  /**
   * runs validation if answers exists and stores them if valid, else if no answers givne allows user to go back
   * @param claimId
   * @param page
   * @param currentIteration
   * @returns true if user should be allowed to go to previous page
   */
  async previousPage(claimId: string, page: Page, currentIteration: number, formState: FormState): Promise<boolean> {
    console.log("Previous called with iter", JSON.parse(JSON.stringify(currentIteration)));

    // If the user has given no answers for the current iteration allow them to go back
    const allAnswersEmpty = this.allAnswersEmpty(page);

    if (allAnswersEmpty) {
      // if allAnswersEmpty is true, we don't want to store this iteration
      this.loadIteration(claimId, page, currentIteration - 1);
      return true;
    }

    // If the user has answers for the current iteration, validate them and store
    const isPageBaseValid = await this.baseValidationService.runBaseValidations(page, formState);

    if (!isPageBaseValid && !allAnswersEmpty) {
      return false;
    }

    this.storeAnswers(claimId, page, currentIteration, currentIteration - 1);
    return true;
  }

  /**
   * Modifies a container and its child blocks to display props correctly when switching between a Container and RepeatableContainer
   * @param {String} containerId - Unique identifier of the container
   * @param {String} containerType - ['BlockContainer', 'BlockRepeatableContainer']
   */
  toggleRepeatablePage(isRepeatable: boolean, page: Page) {
    window.console.log("[toggleRepeatablePage]", isRepeatable, page.repeatableStoreAs);

    if (isRepeatable && !page.repeatableStoreAs) {
      page.repeatableStoreAs = this.builderPropService.generateStoreAs(null, "array");
    }

    const mutator = (block) => {
      block.isRepeatableChild = isRepeatable;
      if (isRepeatable && block.storeAs && !block?.storeAs?.value?.property) {
        block.storeAs = {
          value: "",
          selectedVariable: "",
          required: "",
          type: "string",
          selectedAction: "newProperty",
          variableType: "",
          variableToCreate: "",
        };
      } else if (!isRepeatable && block?.storeAs?.value?.property) {
        // Set block store as back to default variable type as !isRepeatablePage ?
        block.storeAs = {
          value: "",
          selectedVariable: "",
          required: "",
          type: "string",
          selectedAction: "newVariable",
          variableType: "",
          variableToCreate: "",
        };
      }
    };

    PageUtility.traverseBlocksSync(mutator, page);

    return page;
  }

  /**
   * Find all answers within a container and check if they are empty.
   * @returns { Boolean } true if all answers are empty
   */
  allAnswersEmpty = (page: Page): boolean => {
    const { blocks } = page;
    const blocksAreEmpty = [];
    blocks.forEach((block) => {
      const { required, answer } = block as Block;
      // Check if required && if answer is undefined

      if (required && (answer === undefined || !answer || answer?.length === 0)) {
        blocksAreEmpty.push(true);
      } else if (required && answer) {
        blocksAreEmpty.push(false);
      }
    });
    const aBlockHasAnswer = blocksAreEmpty.some((blockIsEmpty) => !blockIsEmpty);

    return !aBlockHasAnswer;
  };
}
