  import { getFirebaseBackend } from "@/authUtils";

/**
 * Service Utility is a Utility Class which helps provide access to external services powering Claims Gate.
 */
class ServiceUtility {
  static getFirestore() {
    return getFirebaseBackend().firestore();
  }

  static getFirebaseAuthentication() {
    return getFirebaseBackend().firebaseAuth();
  }
}

export { ServiceUtility };
