import { getFirebaseBackend } from "@/authUtils";
import { firestore } from "@/types";
import { AsyncHelper } from "@claimsgate/core";
import { ClaimsGateErrors } from "@claimsgate/core";
import { Event, StandardAsyncResult, Workspace } from "@claimsgate/core-types";
import { UserService } from "@/helpers/ClaimsGate/users/UserService";
import { getWorkspace } from "@claimsgate/core";

/** Each of the valid event names */
export type Events = "NewUser" | "NewAgreement" | "NewPage";
const events: Array<Events> = ["NewUser", "NewAgreement", "NewPage"];

export class EventsService {
  db;
  userService: UserService;

  constructor(db?: firestore) {
    if (db) {
      this.db = db;
    } else {
      this.db = getFirebaseBackend().firestore();
    }

    this.userService = new UserService(this.db);
  }

  /** Fires an event to the Claims Gate API */
  async fire(event: Event): Promise<StandardAsyncResult<string, ClaimsGateErrors.EventMissing, any>> {
    try {
      console.log("Firing an event", event);
      if (!event) {
        return AsyncHelper.onError(ClaimsGateErrors.EventMissing);
      }

      // if (!events.includes(event.name)) {
      //   return AsyncHelper.onError(ClaimsGateErrors.EventNameInvalid);
      // }

      const eventsRef = this.db.collection("events").doc();
      const eventId = eventsRef.id;
      const store = { ...event };

      if (event.claimStatus === "testing") {
        const result = await getWorkspace(this.db, event.workspaceId);

        if (result[0]) {
          const workspace: Workspace = result[0];

          if (workspace.preventEventNotification) {
            console.log("Event notifications are disabled for this workspace.");
            return AsyncHelper.onCompleted("Event notifications are disabled.");
          }
        } else {
          return AsyncHelper.onError(ClaimsGateErrors.DataMissing);
        }
      }

      await eventsRef.set(store, { merge: true });

      return AsyncHelper.onCompleted(eventId);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }
}
