// Since we are importing an array of string literals
// we need to import directly from the Typescript file
// and not the wrapping folder "@/types"
import { BlockEmail, BlockPhone, UserBlock, userBlocks } from "@/types/vue/Form";
import { Block } from "@claimsgate/core-types";
import { T2AService } from "@/helpers/ClaimsGate/T2AService";
import { isValidPhoneNumber } from "libphonenumber-js";

export class UserBlockValidatorsService {
  /**
   * Check if a given block name is a user block
   * @param string
   * @returns
   */
  static isUserBlock(string: string): string is UserBlock {
    return (userBlocks as readonly String[]).includes(string);
  }

  /**
   * Runs a given base validator for a given block
   * @param validator Name of the vlaidtor to run
   * @param block
   * @returns
   */
  async runUserBaseValidators(block: Block): Promise<[Block, boolean]> {
    console.log("[baseValidations] running base validations for..", block);
    if (UserBlockValidatorsService.isUserBlock(block.type)) {
      const validator: UserBlock = block.type;
      return await this[validator](block as any);
    } else {
      return [{} as Block, null];
    }
  }

  static stateWatchHandler<I>(BlockInput: I, state: boolean | { [P in keyof I]: boolean }) {
    if (Object(state) !== state) {
      // state is not object
      Object.keys(BlockInput).forEach((input) => {
        BlockInput[input].state = state as boolean;
      });
    } else {
      Object.keys(state).forEach((input) => {
        BlockInput[input].state = state[input];
      });
    }
    return BlockInput;
  }
  static invalidFeedbackWatchHandler<I>(BlockInput: I, invalidFeedback: string | { [P in keyof I]: string }) {
    if (Object(invalidFeedback) !== invalidFeedback) {
      // is not object
      Object.keys(BlockInput).forEach((input) => {
        BlockInput[input].invalidFeedback = invalidFeedback;
      });
    } else {
      Object.keys(invalidFeedback).forEach((input) => {
        BlockInput[input].invalidFeedback = invalidFeedback[input];
      });
    }
    return BlockInput;
  }

  /**
   * Runs the base validatio for the email block
   * @param block
   * @returns
   */
  async BlockEmail(block: BlockEmail | Block): Promise<[BlockEmail | Block, boolean]> {
    const _block = { ...block };

    const { answer } = _block;
    let isValid = true;
    _block.state = {};
    _block.invalidFeedback = {};

    // If the block requires the user to confirm their email, ensure that both
    // of the given emails match
    if (block.requireConfirm) {
      if (!answer.emailConfirm) {
        _block.state.emailConfirm = false;
        _block.invalidFeedback.emailConfirm = "Please re-enter your email";
        isValid = false;
      } else if (answer.email !== answer.emailConfirm) {
        _block.state.emailConfirm = false;
        _block.invalidFeedback.emailConfirm = "Your emails do not match";
        isValid = false;
      } else {
        _block.state.emailConfirm = true;
        _block.invalidFeedback.emailConfirm = "";
      }
    }

    // Validate the given email is deemed valid by T2A
    // const { data: result } = await T2AService.runEmailCheck(answer.email?.toLowerCase(), {
    //   invocationFunction: "BlockEmail",
    //   invocationReason: "deprecated",
    // }); // ? Deprecated
    // if (!result) {
    //   _block.state.email = false;
    //   _block.invalidFeedback.email = "Please enter a valid email address.";
    //   isValid = false;
    // } else {
    //   _block.state.email = true;
    //   _block.invalidFeedback.email = "";
    // }

    return [_block, isValid];
  }

  /**
   * Runs the base validatio for the phone block
   * @param block
   * @returns
   */
  async BlockPhone(block: BlockPhone): Promise<[BlockPhone, boolean]> {
    const _block = { ...block };

    const { answer } = _block;

    let isValid = true;

    _block.state = null;
    _block.invalidFeedback = "";

    if (!isValidPhoneNumber(answer, "GB")) {
      _block.state = false;
      _block.invalidFeedback = "Please enter a valid phone number";
      isValid = false;
    } else {
      _block.state = true;
      _block.invalidFeedback = "";
    }

    return [_block, isValid];
  }
}
