/**
 * Capture Field Mixin provides a series of helper methods which help connect multiple funnels together in Claims
 * Gate
 * @param {*} superclass
 * @returns
 */
export const captureFieldMixin = superclass =>
  class extends superclass {
    /**
     * Adds a new capture field to a page
     */
    addCaptureField(captureFields) {
      const captureFieldStruct = {
        expected: "",
        required: true
      };
      captureFields.push(captureFieldStruct);
    }
    /**
     * Removes a capture field from the page at a given index
     */
    deleteCaptureField(captureFields, captureFieldIndex) {
      /*
      if (captureFields && captureFields.length === 1) {
      return;
      }
      */
      captureFields.splice(captureFieldIndex, 1);
    }
  };
