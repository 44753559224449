import { getInputDefaults } from "@/helpers/ClaimsGate/blocks/inputProps";
import { Props } from "@/types";

export function getProgressProps() {
  return props;
}

export const props = {
  value: {
    type: [Number, String],
    description: "The percent value of the progress bar",
    // valid: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    default: 20,
  } as Props.Num,
  variant: {
    type: String,
    valid: ["primary", "secondary"],
    description: "The color of the progress bar",
    default: "primary",
  } as Props.Variant,
  ...(getInputDefaults(["padding"]) as { padding: Props.Str }),
  height: {
    type: String,
    valid: ["10", "20", "30", "40"],
    description: "The height of the progress bar",
    default: "20",
  } as Props.Str,
  showProgressValue: {
    type: Boolean,
    description: "Show the progress as a percenteage within the bar",
    default: false,
  } as Props.Bool,
  animated: {
    type: Boolean,
    description: "Make the progress bar animated",
    default: false,
  } as Props.Bool,
  width: {
    type: String,
    description: "The width of the progress bar",
    default: "sm",
    valid: ["sm", "md", "lg"],
  } as Props.Str,
  align: {
    type: String,
    description: "Alignment of the progress bar",
    valid: ["left", "center", "right"],
    default: "center",
  } as Props.Align,
};
