import { getFirebaseBackend } from "@/authUtils";
import { getUserId } from "@/helpers/vue/users";
import { getUser } from "@claimsgate/core";
import { User } from "@claimsgate/core-types";

export async function getLoggedInUserData({
  db,
}: {
  db: ReturnType<ReturnType<typeof getFirebaseBackend>["firestore"]>;
}): Promise<User> {
  const userId = getUserId();

  if (!userId) {
    throw new Error("User ID not found");
  }

  const [userData] = await getUser(db, userId);

  if (!userData) {
    throw new Error("User not found");
  }

  if (!userData.userId) {
    userData.userId = userId;
  }

  if (!userData.id) {
    userData.id = userId;
  }

  return userData;
}
