/**
 * Validation Mixin exposes helper methods to add a validation to a page in Claims Gate
 */
export const validationMixin = superclass =>
  class extends superclass {
    /**
     * Adds a validation to the current page
     */
    addValidation(validations) {
      const validationStruct = {
        type: "",
        actual: "",
        error: "",
        value: "",
        conditionals: [{ type: "", actual: "", value: "" }]
      };
      validations.push(validationStruct);
    }
    /**
     * Removes a validation from the current page
     */
    deleteValidation(validations, validationIndex) {
      validations.splice(validationIndex, 1);
    }
  };
