// Declare imports again
import { getUserHelper } from "../UserHelper";
import { getFirebaseBackend } from "@/authUtils";
import { AsyncHelper } from "../AsyncHelper";
import { FunnelsService } from "@claimsgate/core";

/**
 * Events is a class which provides helpful methods to interact with the Claims Gate Event's API
 */
class Events {
  static instance;
  funnelService = new FunnelsService(getFirebaseBackend().firestore());
  /**
   * Returns a copy of the Pixel class Singelton
   * @returns
   */
  constructor() {
    if (this.instance) {
      return this.instance;
    }
    this.instance = this;
  }

  /**
   * Fires an event to the Claims Gate API
   * @param {*} event - the event object
   * @param {*} claimId - the id of the claim
   * @param {*} solicitorUid - the id of the funnel (optional)
   * @returns {AsyncResult} - the result of the call
   */
  async fire(event, claimId, funnelId = null) {
    try {
      // Fetch required data to build the event
      const UserHelper = getUserHelper();

      const { originatorUid } = await UserHelper.getClaimData(claimId);

      // Events are fired to the author of the current user's funnel

      let funnelMeta;
      if (funnelId) {
        ({ data: funnelMeta } = await this.funnels.getFunnelMeta(funnelId));
      }

      const userId = UserHelper.getUserId();

      if (!funnelMeta) {
        return AsyncHelper.onCompleted(false);
      }
      const { authorId } = funnelMeta;

      // Build object for solicitor event
      if (authorId) {
        const solicitorEventStore = {
          userId: userId,
          claimId: claimId,
          event: event,
          solicitorUid: authorId,
        };
        if (funnelId) {
          solicitorEventStore["funnelId"] = funnelId;
        }
        window.console.log("solicitor event sotre", solicitorEventStore);
        // Write solicitor event to Firestore
        await this.write(solicitorEventStore);

        window.console.log("ran and passed write");
      }
      // Build object for originator event
      if (originatorUid) {
        const originatorEventStore = {
          userId: userId,
          claimId: claimId,
          event: event,
          originatorUid: originatorUid,
        };
        if (funnelId) {
          originatorEventStore["funnelId"] = funnelId;
        }
        // Write originator event to Firestore
        await this.write(originatorEventStore);
      }
      return AsyncHelper.onCompleted(true);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Creates a new event in Firestore
   * @param {*} store - Event to store takes the format {claimId: 'x', event: 'x', solicitorUid?: 'x', originatorUid?: 'x', userId: 'x'}
   * @returns {AsyncResult} - the result of the call
   */
  async write(store) {
    try {
      const db = getFirebaseBackend().firestore();
      const eventRef = db.collection("event").doc();
      await eventRef.set(store);
      return AsyncHelper.onCompleted(true);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }
}

export { Events };
