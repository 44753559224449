import { getFirebaseBackend } from "../../../authUtils";
import { AsyncHelper } from "../AsyncHelper";

import { ClaimsGateErrors, Tlw, getFunnelVariables } from "@claimsgate/core";

/**
 * Pixel Initalisation services provides methods which help initalise tracking pixels on Claims Gate
 */
class PixelInitalisationService {
  db = getFirebaseBackend().firestore();
  /**
   * Initalizes each of the pixels which are defined for a given solicitorUid
   * @param {*} workspaceId - The identifier of the workspace whom to define the pixels for
   * @returns {Promise<StandardAsyncResult>}
   */
  async initPixels(workspaceId, funnelId, claimDataService) {
    try {
      console.log(">>> initPixels");
      if (!workspaceId) {
        return AsyncHelper.onError(ClaimsGateErrors.WorkspaceIdMissing);
      }

      if (!funnelId) {
        return AsyncHelper.onError(ClaimsGateErrors.FunnelIdMissing);
      }

      // Initalise each of the available pixels for a given workspace
      const pixelsRef = this.db
        .collection("workspaces")
        .doc(workspaceId)
        .collection("pixels")
        .where("funnelId", "==", funnelId);

      const pixelsSnapshot = await pixelsRef.get();

      if (!pixelsSnapshot.empty) {
        const pixels = pixelsSnapshot.docs.map((document) => document.data());

        console.log(">>> PixelInitSegice", pixels);

        let make;

        if (
          funnelId === Tlw.Funnels.DIESEL_EMISSIONS_FUNNEL_ID ||
          funnelId === Tlw.Funnels.TLW_CLAIM_DETAILS_FUNNEL_ID
        ) {
          const [funnelVariables] = await getFunnelVariables(getFirebaseBackend().firestore(), funnelId);
          const makeVariableId = funnelVariables.find((variable) => variable.field === "make")?.id;
          make = claimDataService.getArtefact(makeVariableId);

          const makeEligible = ["VOLVO", "PEUGEOT", "CITROEN", "BMW", "FORD"].includes(make?.toUpperCase());

          if (!makeEligible) {
            return AsyncHelper.onCompleted(true);
          }
        }

        if (window.vm && window.vm.$analytics) {
          pixels.forEach((pixel) => {
            console.log(">>> Pixels Service Make is", make);
            if (
              funnelId === Tlw.Funnels.DIESEL_EMISSIONS_FUNNEL_ID ||
              funnelId === Tlw.Funnels.TLW_CLAIM_DETAILS_FUNNEL_ID
            ) {
              const makePixelIdMap = {
                VOLVO: ["G-YHFGTZXQQP", "374953901893778", "CRTBEEJC77UF6LFLV520"],
                PEUGEOT: ["G-YHFGTZXQQP", "374953901893778", "CRM0AIRC77U87P3R26OG"],
                CITROEN: ["G-YHFGTZXQQP", "374953901893778", "CRM09MBC77U40MO980F0"],
                BMW: ["G-YHFGTZXQQP", "1295511061837517", "CRTBES3C77UBU0VRNOA0"],
                FORD: ["G-YHFGTZXQQP", "970906034760110", "CRTBFBRC77U6GEBD5K10"],
                JLR: ["G-YHFGTZXQQP", "307320008695149", "CRTBFKBC77U2A26MUPVG"],
              };

              const pixelsToInclude = makePixelIdMap[make] || [];

              if (!pixelsToInclude.includes(pixel.id)) {
                return;
              }
            }

            const { platform, id } = pixel;

            if (platform === "Facebook") {
              this.initFacebookPixel(id);
            } else if (platform === "Taboola" && window._tfa !== undefined) {
              this.initTaboolaPixel(id);
            } else if (platform === "Outbrain" && window.obApi !== undefined) {
              this.initOutbrainsPixel(id);
            } else if (platform === "Twitter") {
              this.initTwitterPixel(id);
            } else if (platform === "Google Analytics" && window.gtag !== undefined) {
              this.initGoogleAnalytics(id);
            } else if (platform === "Google Tag Manager") {
              this.initGoogleTagManager(id);
            } else if (platform === "LinkedIn") {
              this.initLinkedInPixel(id);
            } else if (platform === "Universal Event Tracking (UET)") {
              this.initUETPixel(id);
            } else if (platform === "TikTok") {
              this.initTikTokPixel(id);
            } else if (platform === "Reddit") {
              this.initRedditPixel(id);
            }
          });
        }
      }

      window.console.log("All pixels have been initalized for author: ", workspaceId);
      return AsyncHelper.onCompleted(true);
    } catch (exception) {
      console.error(">>> Failed to initalize pixels!", exception);
      return AsyncHelper.onException(exception);
    }
  }

  initRedditPixel(id) {
    !(function (w, d) {
      if (!w.rdt) {
        var p = (w.rdt = function () {
          p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments);
        });
        p.callQueue = [];
        var t = d.createElement("script");
        (t.src = "https://www.redditstatic.com/ads/pixel.js"), (t.async = !0);
        var s = d.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(t, s);
      }
    })(window, document);
    window.rdt("init", id);
  }

  /** Initalizes an instance of the UET pixel
   * @param {string} id - Identifier of the UET pixel
   * @return {void}
   */
  initUETPixel(id) {
    window.initalizeUet = function (w, d, t, r, u, id) {
      var f, n, i;
      (w[u] = w[u] || []),
        (f = function () {
          var o = { ti: id };
          (o.q = w[u]), (w[u] = new w.UET(o)), w[u].push("pageLoad");
        }),
        (n = d.createElement(t)),
        (n.src = r),
        (n.async = 1),
        (n.onload = n.onreadystatechange =
          function () {
            var s = this.readyState;
            (s && s !== "loaded" && s !== "complete") || (f(), (n.onload = n.onreadystatechange = null));
          }),
        (i = d.getElementsByTagName(t)[0]),
        i.parentNode.insertBefore(n, i);
    };

    window.initalizeUet(window, document, "script", "//bat.bing.com/bat.js", "uetq", id);
  }

  initTikTokPixel(id) {
    if (window.ttq) {
      window.ttq.load(id);
      window.ttq.page();
    }
  }

  /**
   * Initalizes an instance of the Facebook pixel
   * @param {String} id - Identifier of the Facebook pixewl
   * @returns {Boolean} - Boolean indicating if the setup was successful
   */
  initFacebookPixel(id) {
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

    let userId = "";

    const user = getFirebaseBackend().getAuthenticatedUser();
    if (user !== null) {
      userId = user.uid;
    }

    if (userId !== "") {
      window.vm.$analytics.fbq.init(id, {
        external_id: userId,
      });
    } else {
      window.vm.$analytics.fbq.init(id);
    }
    window.vm.$analytics.fbq.event("PageView");

    return true;
  }

  /**
   * Initalizes an instance of the Taboola pixel
   * @param {String} id - Identiifer of the Taboola pixel
   * @returns {Boolean} - Boolean indicating if the setup was successful
   */
  initTaboolaPixel(id) {
    !(function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      "//cdn.taboola.com/libtrc/unip/1/tfa.js",
      "tb_tfa_script"
    );

    window._tfa.push({ notify: "event", name: "page_view", id: id }); // event line
  }

  /**
   * Initalizes an instance of the Outbrains pixel
   * @param {String} id - Identiifer of the Outbrains pixel
   * @return {Boolean} - Boolean indicating if the setup was successful
   */
  initOutbrainsPixel(id) {
    window.obApi.marketerId = id;
    window.obApi("track", "PAGE_VIEW");
  }

  /**
   * Initalizes an instance of the Twitter pixel
   * @param {String} id - Identifier of the Twitter pixel
   * @return {Boolean} - Boolean indicating if the setup was successful
   */
  initTwitterPixel(id) {
    console.log(">>> 1", id);
    console.log(">>> 2", window.twq);
    !(function (e, t, n, s, u, a) {
      e.twq ||
        ((s = e.twq =
          function () {
            s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
          }),
        (s.version = "1.1"),
        (s.queue = []),
        (u = t.createElement(n)),
        (u.async = !0),
        (u.src = "//static.ads-twitter.com/uwt.js"),
        (a = t.getElementsByTagName(n)[0]),
        a.parentNode.insertBefore(u, a));
    })(window, document, "script");

    console.log(">>> 3", window.twq);

    window.isTwitterPixelInitalised = true;
    window.twq("init", id);
    window.twq("track", "Pageview");
  }

  /**
   * Initalizes an instance of Google Analytics
   * @param {*} id - Identifier of the GA or UA4 pixel
   * @return {Boolean} - Boolean indicating if the setup was successful
   */
  initGoogleAnalytics(id) {
    function addToDataLayer() {
      window.dataLayer.push(arguments);
    }
    addToDataLayer("config", id, { send_page_view: true, _ee: true });
  }

  /**
   * Initalizes an instance of Google Tag Manager
   * @param {String} id - Identifier of the Google Tag Manger
   * @return {Boolean} - Boolean indicating if the setup was successful
   */
  initGoogleTagManager(id) {
    window.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    const d = document;
    const l = "dataLayer";
    const f = d.getElementsByTagName("script")[0],
      j = d.createElement("script"),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + id + dl;
    f.parentNode.insertBefore(j, f);
  }

  /**
   * Initalizes an instance of the LinkedIn pixel
   * @param {String} id - Identifier of the LinkedIn pixel
   * @returns {Boolean} - Boolean indicating if the setup was successful
   */
  initLinkedInPixel(id) {
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(id);

    if (window.linktrk === undefined) {
      window.lintrk = function (a, b) {
        window.lintrk.q.push([a, b]);
      };
      window.lintrk.q = [];
    }
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript";
    b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);
    console.log("LinkedIn tracking added");

    return true;
  }
}

export { PixelInitalisationService };
