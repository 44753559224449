// Import Claims Gate classes
import { getFirebaseBackend } from "@/authUtils";
import { AsyncHelper } from "../AsyncHelper";
import { identificationFlow as IdFlows } from "@claimsgate/core";
/**
 * YotiService is a class which exposes helpful methods to interact with the Yoti Identity
 * verification system integrated with Claims Gate
 */
class YotiService {
  firebase = getFirebaseBackend();
  yotiUrl = "https://api.yoti.com/idverify/v1/web/index.html";

  /**
   * Creates a new session with Yoti
   */
  async createSession({
    identificationFlow,
    theme,
    faceMatch,
    livenessCheck,
    claimId,
    funnelId,
  }: {
    identificationFlow: IdFlows;
    theme: { primary: string; secondary: string; text: string };
    faceMatch?: boolean;
    livenessCheck?: boolean;
    claimId?: string;
    funnelId?: string;
  }) {
    try {
      // Create a new Yoti Session
      const createSession = this.firebase.firebaseFunctions().httpsCallable("yotiCreateSession");

      const { data: session } = await createSession({
        identificationFlow,
        theme,
        faceMatch,
        livenessCheck,
        claimId,
        funnelId,
      });

      return AsyncHelper.onCompleted(session);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Generates the SRC which can be used in an iFrame to display Yoti's Identity Verification
   * @returns {string} iframe src
   */
  generateSrc(sessionId: string, sessionToken: string, trackingId?: string): string {
    const searchParams = new URLSearchParams();

    if (sessionId) {
      searchParams.append("sessionID", sessionId);
    }

    if (sessionToken) {
      searchParams.append("sessionToken", sessionToken);
    }

    if (trackingId) {
      searchParams.append("trackingId", trackingId);
    }

    return `${this.yotiUrl}` + "?" + searchParams.toString();
  }

  eventHandler() {}
}

export { YotiService };
