import isNil from "lodash.isnil";
import omitBy from "lodash.omitby";

class VehicleUtility {
  /**
   * Maps a flattened vehicle returned by UK Vehicle Data to the Claims Gate standard
   */
  static mapUkVehicleData(flattenedVehicle: Record<string, any>) {
    window.console.log("[flattenedVehicle]", flattenedVehicle);
    const vehicleMapping = {
      "Response.DataItems.ClassificationDetails.Dvla.Make": "make",
      "Response.DataItems.ClassificationDetails.Dvla.Model": "model",
      "Response.DataItems.VehicleHistory.KeeperChangesList.DateOfLastKeeperChange": "keeperChangeHistory",
      "Response.DataItems.VehicleRegistration.DateFirstRegistered": "dateFirstRegistered",
      "Response.DataItems.TechnicalDetails.General.Engine.Code.CodeList.EngineCode": "engineModelCode",
      "Response.DataItems.TechnicalDetails.General.Engine.Code.CodeList.OemEngineFamily": "engineFamily",
      "Response.DataItems.SmmtDetails.FuelType": "fuelType",
      "Response.DataItems.VehicleRegistration.Vrm": "vehicleRegistration",
      "Response.DataItems.VehicleRegistration.Vin": "vin",
      "Response.DataItems.VehicleRegistration.EngineNumber": "engineNumber",
      "Response.DataItems.VehicleHistory.PlateChangeCount": "plateChangeCount",
      "Response.DataItems.VehicleRegistration.Co2Emissions": "co2",
      "Response.DataItems.SmmtDetails.BodyStyle": "bodyStyle",
      "Response.DataItems.SmmtDetails.NumberOfDoors": "numberOfDoors",
      "Response.DataItems.VehicleHistory.KeeperChangesCount": "keeperChangeCount",
      "Response.DataItems.TechnicalDetails.General.EuroStatus": "euroStatus",
      "Response.DataItems.VehicleRegistration.YearOfManufacture": "yearOfManufacture",
      "Response.DataItems.SmmtDetails.Range": "range",
      "Response.DataItems.SmmtDetails.Series": "series",
      "Response.DataItems.TechnicalDetails.General.Engine.NumberOfCylinders": "numberOfCylinders",
      "Response.DataItems.VehicleRegistration.EngineCapacity": "engineCapacity",
      "Response.DataItems.TechnicalDetails.Performance": "maximumPowerInKw",
      "Response.DataItems.TechnicalDetails.Performance.Power.Bhp": "bhp",
      "Response.DataItems.TechnicalDetails.Performance.Torque.Nm": "torqueNm",
      "Response.DataItems.TechnicalDetails.Performance.Torque.FtLb": "torqueFtLb",
      "Response.DataItems.VehicleRegistration.Colour": "colour",
    };

    const vehicle = {};
    for (const [key, value] of Object.entries(flattenedVehicle)) {
      // window.console.log("Key is: ", key, vehicleMapping[key]);
      if (vehicleMapping[key] && value !== undefined) {
        if (key === "Response.DataItems.TechnicalDetails.General.Engine.Code.CodeList.EngineCode") {
          vehicle[vehicleMapping[key]] = value;
          vehicle["EngineCode"] = value;
        } else if (key === "Response.DataItems.VehicleHistory.KeeperChangesList.") {
          vehicle[vehicleMapping[key]] = JSON.parse(`${value}`);
        } else {
          try {
            vehicle[vehicleMapping[key]] = JSON.parse(value);
          } catch {
            vehicle[vehicleMapping[key]] = value;
          }
        }
      }
    }

    return vehicle;
  }

  static mapCarWebVehicleData(vehicle: Record<string, any>): Record<string, any> {
    const vehicleMapping = {
      dvla_make: "make",
      dvla_model: "model",
      keeperchangehistory: "keeperChangeHistory",
      dateoffirstregistrationuk: "dateFirstRegistered",
      enginemodelcode: "engineModelCode",
      // "Response.DataItems.TechnicalDetails.General.Engine.Code.CodeList.OemEngineFamily": "engineFamily",
      fueltype: "fuelType",
      vrm_curr: "vehicleRegistration",
      vin_original_dvla: "vin",
      enginenumber: "engineNumber",
      // "Response.DataItems.VehicleHistory.PlateChangeCount": "plateChangeCount",
      co_gtokm: "co2",
      bodystyledescription: "bodyStyle",
      numberofdoors: "numberOfDoors",
      numberofpreviouskeepers: "keeperChangeCount",
      eurostatus: "euroStatus",
      dvlayearofmanufacture: "yearOfManufacture",
      modelrangedescription: "range",
      modelseries: "series",
      numberofcylinders: "numberOfCylinders",
      cw_enginecapacity: "engineCapacity",
      maximumpowerinkw: "maximumPowerInKw",
      maximumpowerbhp: "bhp",
      maximumtorquenm: "torqueNm",
      maximumtorquelbft: "torqueFtLb",
      colourcurrent: "colour",
      emissionsRecall: "emissionsRecall",
      mercedesEmissionsRecallMandatoryStatus: "mercedesEmissionsRecallMandatoryStatus",
      mercedesEmissionsRecallEligibleForExclusion: "mercedesEmissionsRecallEligibleForExclusion",
      mercedesEmissionsRecallAccomplished: "mercedesEmissionsRecallAccomplished",
      combined_model: "version",
      nom_cc: "engineSize",
    };
    const mappedVehicle = {};

    Object.keys(vehicle).forEach((carWebKey) => {
      if (vehicleMapping[carWebKey] !== undefined) {
        if (carWebKey === "keeperchangehistory") {
          interface Keeper {
            StartDateOfKeeper: string;
          }

          const keeperChangeList: Array<Keeper> | Keeper = vehicle[carWebKey]?.KeeperChange; // returns array of keeper change objects
          console.log("Keeper change list is", keeperChangeList);
          if (keeperChangeList && Array.isArray(keeperChangeList)) {
            mappedVehicle[vehicleMapping[carWebKey]] = keeperChangeList
              .map((change) => change.StartDateOfKeeper)
              .map((startDate) => new Date(startDate).toISOString());
          } else if (keeperChangeList && !Array.isArray(keeperChangeList) && keeperChangeList.StartDateOfKeeper) {
            // Car web returns object if one keeper change
            mappedVehicle[vehicleMapping[carWebKey]] = [new Date(keeperChangeList.StartDateOfKeeper).toISOString()];
          }
        } else if (carWebKey === "dateoffirstregistrationuk") {
          mappedVehicle[vehicleMapping[carWebKey]] = new Date(vehicle[carWebKey]).toISOString();
        } else if (carWebKey === "nom_cc") {
          mappedVehicle[vehicleMapping[carWebKey]] = parseFloat(vehicle[carWebKey]);
        } else {
          mappedVehicle[vehicleMapping[carWebKey]] = vehicle[carWebKey];
        }
      }
    });

    if (mappedVehicle["engineSize"] && isNaN(mappedVehicle["engineSize"])) {
      delete mappedVehicle["engineSize"];
    }

    const ommitedVehicle = omitBy(mappedVehicle, isNil);
    return ommitedVehicle;
  }
}
export { VehicleUtility };
