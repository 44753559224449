import { ReminderSequence } from "@claimsgate/core-types";
import { v4 as uuidv4 } from "uuid";

/**
 * Capture Field Mixin provides a series of helper methods which help connect multiple funnels together in Claims
 * Gate
 */
export const ReminderSequencesMixin = (superclass: any) =>
  class extends superclass {
    /** Returns the default reminder sequence structure */
    getReminderSequenceStructure(): ReminderSequence {
      const structure: ReminderSequence = {
        id: uuidv4(),
        name: "No Name",
        dateCreated: new Date(),
        status: "inactive",
        trigger: "manual",
        triggerParameters: {
          pageId: "",
          agreementId: "",
          emailRequired: false,
          phoneRequired: false,
        },
        stopConditions: {
          agreementSigned: false,
          page: false,
          claimCompleted: false,
          claimRejected: false,
          claimPaused: false,
        },
        stopConditionParameters: {
          pageId: "",
          agreementId: "",
        },
        messages: [],
      };

      return structure;
    }
    /**
     * Adds a reminder sequence
     */
    addReminderSequence(reminderSequences: Array<ReminderSequence>): void {
      reminderSequences.push(this.getReminderSequenceStructure());
    }

    /**
     * Removes a given reminder sequence by index
     */
    removeReminderSequence(reminderSequences: Array<ReminderSequence>, reminderSequenceIndex: number): void {
      reminderSequences.splice(reminderSequenceIndex, 1);
    }
  };
