import { getFirebaseBackend } from "@/authUtils";
import { Referral, StandardAsyncResult } from "@claimsgate/core-types";
import { ClaimsGateErrors, AsyncHelper } from "@claimsgate/core";

import { firestore } from "@/types";

/** Service class which helps store origination information given in the requested URL on the requesting user's browser */
export class WorkspaceReferralService {
  db: firestore;
  static queryParameters = {
    utmSource: "utm_source",
    utmMedium: "utm_medium",
    utmCampaign: "utm_campaign",
    originatorCode: "ocode",
    workspaceReferrerId: "wref",
  };

  static localStorageParameters = {
    utmSource: "utmSource",
    utmMedium: "utmMedium",
    utmCampaign: "utmCampaign",
    workspaceReferrerId: "workspaceReferrerId",
    adId: "adId",
    refer: "refer",
  };

  constructor(db?: firestore) {
    if (db) {
      this.db = db;
    } else {
      this.db = getFirebaseBackend().firestore();
    }
  }

  getReferralFromLocalStorage(localStorage: Storage): Referral {
    const referrer = {} as any;
    const workspaceReferrerId = localStorage.getItem(
      WorkspaceReferralService.localStorageParameters.workspaceReferrerId
    );
    if (workspaceReferrerId && typeof workspaceReferrerId === "string" && workspaceReferrerId.length > 0) {
      referrer.workspaceReferrerId = workspaceReferrerId;
    }

    const utmMedium = localStorage.getItem(WorkspaceReferralService.localStorageParameters.utmMedium);
    if (utmMedium && typeof utmMedium === "string" && utmMedium.length > 0) {
      referrer.utmMedium = utmMedium;
    }

    const utmSource = localStorage.getItem(WorkspaceReferralService.localStorageParameters.utmSource);
    if (utmSource && typeof utmSource === "string" && utmSource.length > 0) {
      referrer.utmSource = utmSource;
    }

    const utmCampaign = localStorage.getItem(WorkspaceReferralService.localStorageParameters.utmCampaign);
    if (utmCampaign && typeof utmCampaign === "string" && utmCampaign.length > 0) {
      referrer.utmCampaign = utmCampaign;
    }

    // ! Add these for storage
    const adId = localStorage.getItem(WorkspaceReferralService.localStorageParameters.adId);
    if (adId && typeof adId === "string" && adId.length > 0) {
      referrer.adId = adId;
    }

    // Refer is the initial URL which the user used to access Claims Gate
    const refer = localStorage.getItem(WorkspaceReferralService.localStorageParameters.refer);
    if (refer && typeof refer === "string" && refer.length > 0) {
      referrer.refer = refer;
    }

    return referrer;
  }

  /** Stores the referral information from the requested URL in local storage */
  async storeReferrerFromLocalStorage(localStorage: Storage, urlParams: URLSearchParams): Promise<StandardAsyncResult> {
    try {
      let referrerWorkspaceId: string;

      // If the requested URL contains any referral information, we will reset our local storage
      localStorage.setItem(WorkspaceReferralService.localStorageParameters.refer, window?.location?.href);
      if (this.urlContainsReferrer(urlParams)) {
        // Add backwards compatability for originator codes
        if (urlParams.has(WorkspaceReferralService.queryParameters.originatorCode)) {
          const ocode = urlParams.get("ocode");
          const originatorCodeRef = this.db.collection("originator_codes").doc(ocode);
          const originatorCodeSnapshot = await originatorCodeRef.get();

          if (!originatorCodeSnapshot.exists) {
            return AsyncHelper.onError(ClaimsGateErrors.DataMissing);
          }

          const originatorCode = originatorCodeSnapshot.data();
          console.log("ovcode snap", originatorCode);
          referrerWorkspaceId = originatorCode.originatorUid;
        }

        // If the referral data is invalid, break out. Referral data is only valid if it contains a workspaceId
        if (!urlParams.has(WorkspaceReferralService.queryParameters.workspaceReferrerId) && !referrerWorkspaceId) {
          return AsyncHelper.onError(ClaimsGateErrors.DataMissing);
        }

        // Once we are happy the data given in the URL is valid, we can reset the old origination data
        this.clearReferrer(localStorage);

        if (urlParams.has(WorkspaceReferralService.queryParameters.utmSource)) {
          localStorage.setItem(
            WorkspaceReferralService.localStorageParameters.utmSource,
            urlParams.get(WorkspaceReferralService.queryParameters.utmSource)
          );
        }

        if (urlParams.has(WorkspaceReferralService.queryParameters.utmMedium)) {
          localStorage.setItem(
            WorkspaceReferralService.localStorageParameters.utmMedium,
            urlParams.get(WorkspaceReferralService.queryParameters.utmMedium)
          );
        }

        if (urlParams.has(WorkspaceReferralService.queryParameters.utmCampaign)) {
          localStorage.setItem(
            WorkspaceReferralService.localStorageParameters.utmCampaign,
            urlParams.get(WorkspaceReferralService.queryParameters.utmCampaign)
          );
        }

        if (referrerWorkspaceId) {
          localStorage.setItem(
            WorkspaceReferralService.localStorageParameters.workspaceReferrerId,
            referrerWorkspaceId
          );
        } else if (urlParams.has(WorkspaceReferralService.queryParameters.workspaceReferrerId)) {
          localStorage.setItem(
            WorkspaceReferralService.localStorageParameters.workspaceReferrerId,
            urlParams.get(WorkspaceReferralService.queryParameters.workspaceReferrerId)
          );
        }

        return AsyncHelper.onCompleted(true);
      }

      return AsyncHelper.onCompleted(false);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /** Returns a boolean indicating whether the requested URL contains valid origination data in its query parameters */
  urlContainsReferrer(urlParams: URLSearchParams): boolean {
    if (
      (urlParams.has(WorkspaceReferralService.queryParameters.originatorCode) &&
        urlParams.get(WorkspaceReferralService.queryParameters.originatorCode)) ||
      (urlParams.has(WorkspaceReferralService.queryParameters.workspaceReferrerId) &&
        urlParams.get(WorkspaceReferralService.queryParameters.workspaceReferrerId))
    ) {
      return true;
    }

    return false;
  }

  /** Removes all origination data from local storage */
  clearReferrer(localStorage: Storage): boolean {
    localStorage.removeItem(WorkspaceReferralService.localStorageParameters.workspaceReferrerId);
    localStorage.removeItem(WorkspaceReferralService.localStorageParameters.utmSource);
    localStorage.removeItem(WorkspaceReferralService.localStorageParameters.utmMedium);
    localStorage.removeItem(WorkspaceReferralService.localStorageParameters.utmCampaign);

    // Support backwards compatability
    localStorage.removeItem("ocode");

    return true;
  }
}
