import { ServiceUtility } from "@/helpers/ClaimsGate/core/ServiceUtility";
import { AsyncHelper } from "@/helpers/ClaimsGate/AsyncHelper";

import { ClaimsGateErrors } from "@claimsgate/core";
import firebase from "firebase/compat";
import { StandardAsyncResult, Theme } from "@/types";

interface FontMap {
  serifFontFamilies: {
    name: string;
    style: {
      "font-family": string;
    };
    url?: string;
  }[];
  sanSerifFontFamilies: {
    name: string;
    style: {
      "font-family": string;
    };
    url?: string;
  }[];
}

/**
 * Service supports themes used in ClaimsGate
 */
export class ThemesService {
  db: firebase.firestore.Firestore;
  auth: firebase.auth.Auth;

  constructor(db?: firebase.firestore.Firestore, auth?: firebase.auth.Auth) {
    if (db) {
      this.db = db;
      this.auth = auth;
    } else {
      this.db = ServiceUtility.getFirestore();
      this.auth = ServiceUtility.getFirebaseAuthentication();
    }
  }

  // ------------------------------ Functions to work with Claims Gate themes ------------------------------

  /**
   * Adds a given Theme to Claims Gate Themes
   * @param  {Object} theme - theme object
   * @returns {Promise<StandardAsyncResult<Theme>}
   */
  async addTheme(theme: Theme): Promise<StandardAsyncResult> {
    try {
      if (!theme) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemeMissing);
      }

      const themeRef = this.db.collection("themes").doc();
      const themeId = themeRef.id;

      await themeRef.set({ id: themeId, ...theme }, { merge: true });
      const themeSnapshot = await themeRef.get();
      const themeData = themeSnapshot.data();
      return AsyncHelper.onCompleted(themeData);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Update Theme details on the Claims Gate Themes
   * @param  {String} themeId - unique theme identifier
   * @param  {Object} theme - theme object
   * @returns {Promise<StandardAsyncResult>}
   */
  // ? Do we want to allow users to change themes
  async updateTheme(themeId: string, theme: Theme): Promise<StandardAsyncResult> {
    try {
      if (!themeId) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemeIdMissing);
      }
      if (!theme) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemeMissing);
      }

      // check if theme is on the workspace
      const themeQueryRef = await this.db.collection("themes").doc(themeId);

      await themeQueryRef.set(theme, { merge: true });
      return AsyncHelper.onCompleted(true);
    } catch (exception) {
      console.error(exception);
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Returns the requested Theme details
   * @param  {String} themeId - unique theme identifier
   * @returns {Promise<StandardAsyncResult>}
   */
  async getTheme(themeId: string): Promise<StandardAsyncResult> {
    try {
      if (!themeId) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemeIdMissing);
      }

      // check if theme is on the workspace
      const themeQueryRef = await this.db.collection("themes").doc(themeId);
      const themeQuerySnapshot = await themeQueryRef.get();
      if (!themeQuerySnapshot.exists) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemeDoesNotExist, themeId);
      }

      return AsyncHelper.onCompleted(themeQuerySnapshot.data());
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Removes a given Theme from Claims Gate
   * @param  {String} themeId - unique theme identifier
   * @returns {Promise<StandardAsyncResult>}
   */
  // ? Do we want to allow users to delete themes from Claims Gate
  async removeTheme(themeId: string): Promise<StandardAsyncResult> {
    try {
      if (!themeId) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemeIdMissing);
      }

      const res = await this.db.collection("themes").doc(themeId).delete();

      return AsyncHelper.onCompleted(res);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Generates a unique themeId in Firestore
   */
  async generateThemeId(): Promise<StandardAsyncResult> {
    const themesRef = this.db.collection("themes").doc();

    return AsyncHelper.onCompleted(themesRef.id);
  }

  /**
   * Returns the themes available on Claims Gate
   * @returns {Promise<StandardAsyncResult>}
   */
  async getThemes(): Promise<StandardAsyncResult> {
    try {
      const themesRef = this.db.collection("themes");
      const themesSnapshot = await themesRef.get();

      if (themesSnapshot.empty) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemesDoNotExist);
      }
      const themes = themesSnapshot.docs.map((document) => document.data());

      return AsyncHelper.onCompleted(themes);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  // ------------------------------ Functions to work with workspace themes ------------------------------

  /**
   * Adds a given Theme to the Workspace
   * @param  {String} workspaceId - unique Workspace identifier
   * @param  {Object} theme - theme object
   * @returns {Promise<StandardAsyncResult<Theme>}
   */
  async addWorkspaceTheme(workspaceId: string, theme: Theme): Promise<StandardAsyncResult> {
    try {
      if (!workspaceId) {
        return AsyncHelper.onError(ClaimsGateErrors.WorkspaceIdMissing);
      }
      if (!theme) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemeMissing);
      }

      const themeRef = this.db.collection("workspaces").doc(workspaceId).collection("themes").doc();
      const themeId = themeRef.id;

      await themeRef.set({ id: themeId, ...theme, workspaceId: workspaceId }, { merge: true });
      const themeData = (await themeRef.get()).data();
      return AsyncHelper.onCompleted(themeData);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Update Theme details on a Workspace
   * @param  {String} workspaceId - unique workspace identifier
   * @param  {String} themeId - unique theme identifier
   * @param  {Object} theme - theme object
   * @returns {Promise<StandardAsyncResult>}
   */
  async updateWorkspaceTheme(workspaceId: string, themeId: string, theme: Theme): Promise<StandardAsyncResult> {
    try {
      if (!workspaceId) {
        return AsyncHelper.onError(ClaimsGateErrors.WorkspaceIdMissing);
      }
      if (!themeId) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemeIdMissing);
      }
      if (!theme) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemeMissing);
      }

      // check if theme is on the workspace
      const themeQueryRef = await this.db.collection("workspaces").doc(workspaceId).collection("themes").doc(themeId);
      const themeQuerySnapshot = await themeQueryRef.get();
      if (!themeQuerySnapshot.exists) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemeDoesNotExist, themeId);
      }

      await themeQueryRef.set(theme, { merge: true });
      return AsyncHelper.onCompleted(true);
    } catch (exception) {
      console.error(exception);
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Returns the requested Theme details
   * @returns {Promise<StandardAsyncResult>}
   */
  async getDefaultTheme(): Promise<StandardAsyncResult> {
    try {
      // check if theme is on the workspace
      const themeQueryRef = this.db.collection("themes").doc("WPrvkaSO2wNONWFQpoPu");
      const themeQuerySnapshot = await themeQueryRef.get();
      if (!themeQuerySnapshot.exists) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemeDoesNotExist);
      }

      const theme = themeQuerySnapshot.data();

      return AsyncHelper.onCompleted(theme);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Returns the requested Theme details

   * @param  {String} themeId - unique theme identifier
   * @returns {Promise<StandardAsyncResult>}
   */
  async getWorkspaceTheme(themeId: string): Promise<StandardAsyncResult> {
    try {
      if (!themeId) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemeIdMissing);
      }

      // check if theme is on the workspace
      const themeQueryRef = this.db.collection("themes").doc(themeId);
      const themeQuerySnapshot = await themeQueryRef.get();
      if (!themeQuerySnapshot.exists) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemeDoesNotExist, themeId);
      }

      return AsyncHelper.onCompleted(themeQuerySnapshot.data());
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Removes a given Theme from the Workspace
   * @param  {String} workspaceId - unique workspace identifier
   * @param  {String} themeId - unique theme identifier
   * @returns {Promise<StandardAsyncResult>}
   */
  async removeWorkspaceTheme(workspaceId: string, themeId: string): Promise<StandardAsyncResult> {
    try {
      if (!workspaceId) {
        return AsyncHelper.onError(ClaimsGateErrors.WorkspaceIdMissing);
      }
      if (!themeId) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemeIdMissing);
      }

      const res = await this.db.collection("workspaces").doc(workspaceId).collection("themes").doc(themeId).delete();

      return AsyncHelper.onCompleted(res);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Returns the themes associated with a Workspace
   * @param {String} workspaceId - unique workspace identifier
   * @returns {Promise<StandardAsyncResult>}
   */
  async getWorkspaceThemes(workspaceId: string): Promise<StandardAsyncResult> {
    try {
      if (!workspaceId) {
        return AsyncHelper.onError(ClaimsGateErrors.WorkspaceIdMissing);
      }

      // get workspace specific themes
      const themesRef = this.db.collection("themes").where("workspaceId", "==", workspaceId);
      const themesSnapshot = await themesRef.get();

      let themes = [];
      if (!themesSnapshot.empty) {
        themes = themesSnapshot.docs.map((document) => document.data());
        // return AsyncHelper.onError(ClaimsGateErrors.ThemesDoNotExist);
      }

      return AsyncHelper.onCompleted(themes);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Update Theme selected on a Workspace
   * @param  {String} workspaceId - unique workspace identifier
   * @param  {String} themeId - unique theme identifier
   * @returns {Promise<StandardAsyncResult>}
   */
  async selectWorkspaceTheme(workspaceId: string, themeId: string): Promise<StandardAsyncResult> {
    try {
      if (!workspaceId) {
        return AsyncHelper.onError(ClaimsGateErrors.WorkspaceIdMissing);
      }
      if (!themeId) {
        return AsyncHelper.onError(ClaimsGateErrors.ThemeIdMissing);
      }

      const workspaceRef = await this.db.collection("workspaces").doc(workspaceId);

      // // check if theme is on the workspace
      // const themeQueryRef = workspaceRef.collection("themes").doc(themeId);
      // const themeQuerySnapshot = await themeQueryRef.get();
      // if (!themeQuerySnapshot.exists) {
      //   return AsyncHelper.onError(ClaimsGateErrors.ThemeDoesNotExist, themeId);
      // }

      const selectedTheme = { themeSelectedId: themeId };

      await workspaceRef.set(selectedTheme, { merge: true });

      return AsyncHelper.onCompleted(true);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  static fontFamilyMap(): FontMap {
    return {
      serifFontFamilies: [
        {
          name: "Adamina",
          style: { "font-family": `Adamina !important` },
          url: "https://fonts.googleapis.com/css?family=Adamina&display=swap",
        },
        {
          name: "Crete Round",
          style: { "font-family": `Crete Round !important` },
          url: "https://fonts.googleapis.com/css?family=Crete+Round&display=swap",
        },
        {
          name: "Domine",
          style: { "font-family": `Domine !important` },
          url: "https://fonts.googleapis.com/css?family=Domine&display=swap",
        },
        {
          name: "Droid Serif",
          style: { "font-family": `Droid Serif !important` },
          url: "https://fonts.googleapis.com/css?family=Droid+Serif&display=swap",
        },
        {
          name: "Lora",
          style: { "font-family": `Lora !important` },
          url: "https://fonts.googleapis.com/css?family=Lora&display=swap",
        },
        {
          name: "Merriweather",
          style: { "font-family": `Merriweather !important` },
          url: "https://fonts.googleapis.com/css?family=Merriweather&display=swap",
        },
        {
          name: "Noto Serif",
          style: { "font-family": `Noto Serif !important` },
          url: "https://fonts.googleapis.com/css?family=Noto+Serif&display=swap",
        },
        {
          name: "Playfair Display",
          style: { "font-family": `Playfair Display !important` },
          url: "https://fonts.googleapis.com/css?family=Playfair+Display&display=swap",
        },
        {
          name: "Inter",
          style: { "font-family": `Inter !important` },
          url: "https://fonts.googleapis.com/css?family=Inter&display=swap",
        },
      ],

      sanSerifFontFamilies: [
        {
          name: "Poppins",
          style: { "font-family": `Poppins !important` },
          url: "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap",
        },
        {
          name: "Open Sans",
          style: { "font-family": `Open Sans !important` },
          url: "https://fonts.googleapis.com/css?family=Open+Sans&display=swap",
        },

        {
          name: "Roboto",
          style: { "font-family": `Roboto !important` },
          url: "https://fonts.googleapis.com/css?family=Roboto&display=swap",
        },
        {
          name: "Lato",
          style: { "font-family": `Lato !important` },
          url: "https://fonts.googleapis.com/css?family=Lato&display=swap",
        },
        {
          name: "Droid Sans",
          style: { "font-family": `Droid Sans !important` },
          url: "https://fonts.googleapis.com/css?family=Droid+Sans&display=swap",
        },
        {
          name: "Fira Sans",
          style: { "font-family": `Fira Sans !important` },
          url: "https://fonts.googleapis.com/css?family=Fira+Sans&display=swap",
        },

        {
          name: "Ubuntu",
          style: { "font-family": `Ubuntu !important` },
          url: "https://fonts.googleapis.com/css?family=Ubuntu&display=swap",
        },
        {
          name: "Noto Sans",
          style: { "font-family": `Noto Sans !important` },
          url: "https://fonts.googleapis.com/css?family=Noto+Sans&display=swap",
        },
        {
          name: "Oxygen",
          style: { "font-family": `Oxygen !important` },
          url: "https://fonts.googleapis.com/css?family=Oxygen&display=swap",
        },
        {
          name: "Oswald",
          style: { "font-family": `Oswald !important` },
          url: "https://fonts.googleapis.com/css?family=Oswald&display=swap",
        },
        {
          name: "Fjalla One",
          style: { "font-family": `Fjalla One !important` },
          url: "https://fonts.googleapis.com/css?family=Fjalla+One&display=swap",
        },
        {
          name: "Helvetica Neue LT Pro",
          style: { "font-family": `Helvetica Neue LT Pro !important` },
        },
        {
          name: "'Source Sans 3'",
          style: { "font-family": `'Source Sans 3' !important` },
          url: "https://fonts.googleapis.com/css2?family=Source+Sans+3&display=swap",
        },
      ],
    };
  }
}
