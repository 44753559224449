import { AsyncHelper } from "../AsyncHelper";
import { ClaimsGateErrors, Tlw } from "@claimsgate/core";
import { Event } from "@claimsgate/core-types";

/**
 * Pixel is a class which provides helpful methods to interact with the Claims Gate Tracking API
 */
export class PixelsService {
  /**
   * Fires an event to each of the currently loaded in pixels
   */
  async fire(event: Event) {
    try {
      if (!event) {
        return AsyncHelper.onError(ClaimsGateErrors.EventMissing);
      }

      // Build meta
      const eventName = JSON.parse(JSON.stringify(event.name));
      const eventMeta = event;

      // Generate a consistent event_id for deduplication
      const eventId = event.claimId ? `${event.claimId}_${event.name}` : null;

      // Fire facebook with proper event_id for deduplication with CAPI
      if (window["fbq"] && typeof window["fbq"] === "function") {
        // Only pass event_id if we have a claimId to match server-side format
        if (eventId) {
          if (event.name === "NewFinancialAgreement") {
            window["fbq"](
              "track",
              "Lead",
              {
                ...eventMeta,
                eventID: eventId,
              },
              { eventID: eventId, event_id: eventId }
            );
          } else {
            console.log("Firing facebook event with event_id", eventId);
            window["fbq"](
              "track",
              eventName,
              {
                ...eventMeta,
                eventID: eventId,
              },
              {
                eventID: eventId,
                event_id: eventId,
              }
            );
          }
        } else {
          window["vm"]["$analytics"].fbq.event(eventName, eventMeta);
        }
      }

      // Fire twitter
      if (window["twq"] && window.isTwitterPixelInitalised) {
        window["twq"]("track", eventName);
      }

      // Fire UETQ
      if (window["uetq"]) {
        window["uetq"].push("event", `${eventName}`, {
          event_label: `${eventName}`,
        });
      }

      // Fire google
      if (window["gtag"]) {
        window["vm"]["$gtag"].event(eventName, { ...eventMeta });
      }

      // Fire TFA
      if (window["_tfa"]) {
        if (window["_tfa"].TUP) {
          const pageViewAccountIds = window["_tfa"].TUP.pageViewAccountIds;
          if (pageViewAccountIds && typeof pageViewAccountIds === "object") {
            Object.keys(pageViewAccountIds).forEach((pageViewAccountId) => {
              window["_tfa"].push({
                notify: "event",
                name: eventName,
                id: pageViewAccountId,
                ...eventMeta,
              });
            });
          }
        }
      }

      // Fire ooblocks
      if (window["obApi"]) {
        window["obApi"]("track", eventName, { ...eventMeta });
      }

      // Fire LinkedIn
      if (window["lintrk"]) {
        window["lintrk"]("track", eventName, { ...eventMeta });
        console.log("Fired event", eventName, eventMeta);

        if (eventName === "NewAgreement" && event?.funnelId) {
          window["lintrk"]("track", { conversion_id: 17855969 });
        } else if (eventName === "NewUser" && event?.funnelId) {
          window["lintrk"]("track", { conversion_id: 17855977 });
        }
      }

      // Fire Tiktok
      if (window["ttq"]) {
        // ! Don't fire the Tiktok Pixel for the TLW Diesel Emissions Funnel

        console.log(">>> eventName", eventName, event.funnelId);
        if (eventName === "NewSignup") {
          window["ttq"].track("CompleteRegistration", {}, { event_id: `${event.claimId}${event.name}` });
        } else if (eventName === "NewAgreement" && event.funnelId !== Tlw.Funnels.DIESEL_EMISSIONS_FUNNEL_ID) {
          window["ttq"].track("CompleteRegistration", {}, { event_id: `${event.claimId}${event.name}` });
        } else if (eventName === "NewUser") {
          window["ttq"].track("ClickButton", {}, { event_id: `${event.claimId}${event.name}` });
        }
      }

      // Fire Reddit
      if (window["rdt"]) {
        if (eventName === "NewAgreement") {
          window["rdt"]("track", "SignUp");
        }

        if (eventName === "NewUser") {
          window["rdt"]("track", "Lead");
        }
      }

      return AsyncHelper.onCompleted(true);
    } catch (exception) {
      window.console.error("Firing pixel error", exception);
      return AsyncHelper.onException(exception);
    }
  }
}
