import { MutatorFunction, UserBlock } from "@/types";
import { Page, Block, Compute } from "@claimsgate/core-types";
import { BaseValidationService, UserBlockValidatorsService } from "@/helpers/ClaimsGate/pages/validations";
import { PageHelper } from "@/helpers/ClaimsGate/funnels/PageHelper.js";
import { UserVariableService } from "@/helpers/ClaimsGate/UserVariableService";

import parsePhoneNumber from "libphonenumber-js";

import { getFirebaseBackend } from "@/authUtils";
// ! This will not work for a ts file. Needs to be used fro .vue file
import { PageUtility } from "..";

type FirebaseAuth = firebase.default.auth.Auth;

const userBlockToCompute = {
  BlockEmail: "storeEmail",
  BlockPhone: "storePhoneNumber",
};

const uiMessages = {
  emailAlreadyInUse: "This email is associated with another account.",
  phoneAlreadyInUse: "This phone number is associated with another account.",
};
export class UserBlockComputeService {
  baseValidationService: BaseValidationService;
  userBlockValidatorsService: UserBlockValidatorsService;
  auth: FirebaseAuth;

  constructor(auth?: FirebaseAuth) {
    if (auth) {
      this.auth = auth;
    } else {
      this.auth = getFirebaseBackend().firebaseAuth();
    }

    this.baseValidationService = new BaseValidationService();
    this.userBlockValidatorsService = new UserBlockValidatorsService();
  }

  /** Runs a user computes */
  async runCompute(name: UserBlock, page: Page, claimId: string) {
    console.log("[computes] Running a user block compute", name, page, claimId);
    if (UserBlockValidatorsService.isUserBlock(name)) {
      return this[userBlockToCompute[name]](page, claimId);
    } else {
      return { error: "The page has been set up incorrectly." };
    }
  }
  /** Calculates the additional user block computes to run for a given page */
  async calculateUserBlockComputes(page: Page): Promise<Array<Compute>> {
    const computes: Array<Compute> = [];
    const mutator: MutatorFunction<Promise<void>> = async (block: Block) => {
      // If the block we are looking at is an instance of a user block
      // then we will need to add the name of the block to the list of additional
      // computes for execution
      if (UserBlockValidatorsService.isUserBlock(block.type)) {
        computes.push({ name: block.type } as Compute);
      }
    };

    await PageUtility.traverseBlocks(mutator, page, "cols");

    return computes;
  }

  // async storeEmail(page: Page, claimId: string) {
  //   // Fetch the email and phone number from the page
  //   const email = PageHelper.loadVariable(page, "email", claimId);

  //   // Check if there is already a user who exists with the given email address
  //   const { data: userExistResult, error, exception } = await UserVariableService.checkIfUserExists(email, null);

  //   if (!userExistResult || error || exception) {
  //     return { error: "Please try again. " };
  //   }

  //   // If the given email address already belongs to an existing user
  //   if (userExistResult.status) {
  //     const user = this.auth.currentUser;

  //     // If the email already belongs to an existing user
  //     if (userExistResult.user.email) {
  //       // If the email which belongs to the currently authenticated user is not equal to
  //       // the email which was entered, then they need to try to sign in to the
  //       // account which is associated with the given email.
  //       if (email !== user.email) {
  //         // Fire a sweet alert asking if the user would like to recover the account
  //         // associated with the email address.
  //         const emailInUseAlert = await this.infoModalService.fire("error", {
  //           title: `This email is already associated with another account.`,
  //           text: "Would you like to recover your account?",
  //           confirmButtonText: "Recover",
  //           cancelButtonText: "Close",
  //           showCancelButton: true,
  //         });

  //         if (emailInUseAlert.isConfirmed) {
  //           window.vm.$router.push({ name: "logout" });

  //           // If it is the user's email which is already in use, allow them to redirect
  //           // to the login passing their email
  //           window.vm.$router.push({
  //             name: "login",
  //             params: { userIdentifier: email },
  //           });
  //         }
  //         return { error: { message: "Email already in use?", field: "email" } };
  //       }
  //     }
  //   }

  //   // Update the email associated with the user's account
  //   await getFirebaseBackend().updateEmail(email);
  //   return { result: true };
  // }

  // async storePhoneNumber(page: Page, claimId: string) {
  //   // Fetch the phone number from the page
  //   const phoneNumber = PageHelper.loadVariable(page, "phoneNumber", claimId);

  //   // Parse a compilant number from the user given phone number
  //   const parsedPhoneNumber = parsePhoneNumber(phoneNumber, "GB").number;

  //   const {
  //     data: userExistResult,
  //     error,
  //     exception,
  //   } = await UserVariableService.checkIfUserExists(null, parsedPhoneNumber);

  //   if (!userExistResult || error || exception) {
  //     return { error: true };
  //   }

  //   if (userExistResult.status) {
  //     const user = this.auth.currentUser;

  //     // If the given phone number already belongs to a user
  //     if (userExistResult.user.phoneNumber) {
  //       // If the phone number does not belong to the currently authenticatied user
  //       // then we will need to show them a way to recover the account which is associated with
  //       // the given phone number
  //       if (phoneNumber !== user.phoneNumber) {
  //         // Fire a sweet alert asking if the user would like to recover the account
  //         // associated with the phone number.
  //         this.infoModalService
  //           .fire("error", {
  //             title: uiMessages.phoneAlreadyInUse,
  //             text: "Would you like to recover your account?",
  //             confirmButtonText: "Recover",
  //             cancelButtonText: "Close",
  //             showCancelButton: true,
  //           })
  //           .then((phoneInUseAlert) => {
  //             if (phoneInUseAlert.isConfirmed) {
  //               window.vm.$router.push({ name: "logout" });

  //               // If it is the user's email which is already in use, allow them to redirect
  //               // to the login passing their email
  //               window.vm.$router.push({
  //                 name: "login",
  //                 params: { userIdentifier: phoneNumber },
  //               });
  //             }
  //           });

  //         return {
  //           error: { message: uiMessages.phoneAlreadyInUse, field: "phoneNumber" },
  //         };
  //       }
  //     }
  //   }

  //   return { result: true };
  // }
}
