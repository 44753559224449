// Import packages
import { v4 as uuidv4 } from "uuid";
import compose from "lodash/fp/compose";

// Import ClaimsGate classes
import { BuilderUtility } from "@/helpers/ClaimsGate/builder/BuilderUtility";
import { AsyncHelper } from "@/helpers/ClaimsGate/AsyncHelper";
import { getFirebaseBackend } from "@/authUtils.js";

// Import mixins
import { conditionalMixin } from "@/helpers/ClaimsGate/builder/modules/ConditionalMixin";
import { validationMixin } from "@/helpers/ClaimsGate/builder/modules/ValidationMixin";
import { webhookMixin } from "@/helpers/ClaimsGate/builder/modules/WebhookMixin";
import { eventsMixin } from "@/helpers/ClaimsGate/builder/modules/EventsMixin";
import { captureFieldMixin } from "@/helpers/ClaimsGate/builder/modules/CaptureFieldMixin";
import { messagesMixin } from "@/helpers/ClaimsGate/builder/modules/MessagesMixin";
import { ReminderSequencesMixin } from "@/helpers/ClaimsGate/builder/modules/ReminderSequencesMixin";

import { BuilderPropService } from "@/helpers/ClaimsGate/builder/BuilderPropService";

/* eslint-disable no-unused-vars */

import { AsyncResult, StandardAsyncResult } from "@/types";
import { Block, Container, Row, Variable } from "@claimsgate/core-types";
/**
 * Builder Base imports each of the module mixins
 */
class BuilderBase {}
const BuilderModules = compose(
  captureFieldMixin,
  messagesMixin,
  ReminderSequencesMixin,
  conditionalMixin,
  eventsMixin,
  validationMixin,
  webhookMixin
)(BuilderBase);

/**
 * Builder Service provides helpful methods to create a funnel in Claims Gate
 */
class BuilderService extends BuilderModules {
  db = getFirebaseBackend().firestore();
  builderPropService = new BuilderPropService();

  /**
   * Returns the default block structure
   * @returns  {Object} - contains a uuid4 id
   */
  getDefaultStruct(): object {
    return { id: this.generateUuid() };
  }

  /**
   * Returns the default row structure
   * @returns {Object}
   */
  getRowStruct(): Row {
    return { ...this.getDefaultStruct(), type: "BlockRow", cols: [] };
  }

  /**
   * Returns the defualt container structure
   * @returns  {Object}
   */
  getContainerStruct(): Container {
    const type = "BlockContainer";

    return {
      ...this.getDefaultStruct(),
      type: type,
      rows: [],
      ...BuilderService.getProps(type),
    } as Container;
  }

  /**
   * Returns the defualt column structure
   * @returns {Object}
   */
  getColStruct(): object {
    return { ...this.getDefaultStruct(), type: "BlockColumn", blocks: [] };
  }

  deleteComponent(blocks: any, id: any) {
    // Find the object which holds the
    const componentArray = BuilderUtility.find(blocks, "id", id, true);

    const componentIndex = componentArray.findIndex((block: { id: any }) => block.id === id);

    const component = componentArray[componentIndex];

    // Track each of the components which were moved as a result of this operation
    // and return their component ids
    const componentsRemoved = [];

    // If the component to remove is a container
    if (component.rows) {
      component.rows.forEach((row: { id: any; cols: any[] }) => {
        componentsRemoved.push(row.id);
        row.cols.forEach((col: { id: any; blocks: any[] }) => {
          componentsRemoved.push(col.id);
          col.blocks.forEach((block: { id: any }) => {
            componentsRemoved.push(block.id);
          });
        });
      });
      // If the component to remove is a column
    } else if (component.cols) {
      component.cols.forEach((col: { id: any; blocks: any[] }) => {
        componentsRemoved.push(col.id);
        col.blocks.forEach((block: { id: any }) => {
          componentsRemoved.push(block.id);
        });
      });
      // If the component to remove is a column
    } else if (component.blocks) {
      component.blocks.forEach((block: { id: any }) => {
        componentsRemoved.push(block.id);
      });
      // If the component to remove is a block
    } else {
      componentsRemoved.push(component.id);
    }

    componentArray.splice(componentIndex, 1);
    return componentsRemoved;
  }

  /**
   * Returns the list of user variables from Firestore
   * @returns {Array|null} - List of variables, otherwise null
   */
  async getUserVariables(): Promise<StandardAsyncResult> {
    try {
      const variablesRef = this.db.collection("variables");
      const variablesQueryRef = variablesRef.where("group", "==", "user");
      const variablesQuerySnapshot = await variablesQueryRef.get();

      if (!variablesQuerySnapshot.empty) {
        const variablesQueryData = variablesQuerySnapshot.docs.map((document) => document.data());
        return AsyncHelper.onCompleted(variablesQueryData);
      }
      return AsyncHelper.onCompleted(null);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  async getVariable(funnelId: string, variableId: any) {
    const funnelRef = this.db.collection("funnels").doc(funnelId);

    const variablesRef = funnelRef.collection("variables").where("id", "==", variableId);
    const variablesQuerySnapshot = await variablesRef.get();

    if (variablesQuerySnapshot.empty) {
      return AsyncHelper.onCompleted(null);
    }
    const variableSnapshot = variablesQuerySnapshot.docs[0];
    return AsyncHelper.onCompleted(variableSnapshot.data());
  }

  /**
   * Returns the list of user variables from Firestore
   * @param {funnelId} - Identifier of the funnel
   * @returns {Array|null} - List of variables, otherwise null
   */
  async getClaimVariables(funnelId: string): Promise<StandardAsyncResult> {
    try {
      const funnelRef = this.db.collection("funnels").doc(funnelId);
      const funnelVariablesRef = funnelRef.collection("variables");
      const funnelVariablesQueryRef = funnelVariablesRef.where("group", "==", "claim");
      const funnelVariablesQuerySnapshot = await funnelVariablesQueryRef.get();

      if (!funnelVariablesQuerySnapshot.empty) {
        const variablesQueryData = funnelVariablesQuerySnapshot.docs.map((document) => document.data());
        return AsyncHelper.onCompleted(variablesQueryData);
      } else {
        return AsyncHelper.onCompleted(null);
      }
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Returns the list of user variables from Firestore
   * @param {funnelId} - Identifier of the funnel
   * @returns {Array|null} - List of variables, otherwise null
   */
  async getTrackingVariables(funnelId: string): Promise<StandardAsyncResult> {
    try {
      const funnelRef = this.db.collection("funnels").doc(funnelId);
      const funnelVariablesRef = funnelRef.collection("variables");
      const funnelVariablesQueryRef = funnelVariablesRef.where("group", "==", "tracking");
      const funnelVariablesQuerySnapshot = await funnelVariablesQueryRef.get();

      if (!funnelVariablesQuerySnapshot.empty) {
        const variablesQueryData = funnelVariablesQuerySnapshot.docs.map((document) => document.data());
        return AsyncHelper.onCompleted(variablesQueryData);
      } else {
        return AsyncHelper.onCompleted(null);
      }
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }
  /**
   * Adds a property to a variable's structure
   * @property { String } funnelId
   * @property { String } variableId
   * @property { Object } property
   * @returns { Promise<AsyncResult<boolean>>}
   */
  async addProperty(
    funnelId: string,
    variableId: string,
    property: { field: string | number; type: any }
  ): Promise<AsyncResult<boolean>> {
    // Get the variable
    const funnelRef = this.db.collection("funnels").doc(funnelId);
    const variableRef = funnelRef.collection("variables").doc(variableId);
    const variableSnapshot = await variableRef.get();

    if (!variableSnapshot.exists) {
      return AsyncHelper.onCompleted(null);
    }

    const variable = variableSnapshot.data();

    if (!variable.structure) {
      variable.structure = {};
    }
    variable.structure[property.field] = property.type;

    await variableRef.update(variable);

    return AsyncHelper.onCompleted(true);
  }

  async createVariable(
    funnelId: string,
    field: any,
    type: any,
    group: Variable["group"],
    id = "",
    structure: Variable["structure"]
  ) {
    try {
      // Define groups
      const groups = {
        user: "user",
        claim: "claim",
        tracking: "tracking",
      };

      if (group === groups.user || group === groups.tracking) {
        const id = this.createVariableId();
        const store = { id: id, field: field, type: type, group: group };

        await this.db.collection("variables").doc(id).set(store, { merge: true });
      } else if (group === groups.claim) {
        // Attempt to create the variable for this funnel
        const funnelRef = this.db.collection("funnels").doc(funnelId);
        const funnelVariablesRef = funnelRef.collection("variables");

        const variableRef = funnelVariablesRef.doc(id);
        const store: Variable = { id: id, field: field, type: type, group: group };

        if (type === "Array<T>" || type === "T") {
          store.structure = structure;
        }

        await variableRef.set(store, { merge: true });
      }
      return AsyncHelper.onCompleted(true);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Generates a universally unique identifier for a block in a page
   * @returns {string} - Universally unique identiifer
   */
  generateUuid(): string {
    return uuidv4();
  }

  /**
   * Adds the default container to the given page
   * @param {Array} blocks - Array of blocks associated with the page
   * @param {Number} containerIndex - Index of where to add the container
   */
  addContainer(blocks: Array<Container>, containerIndex: number = null): string {
    if (!containerIndex) {
      console.log("ok");
    }

    const container: Container = this.getContainerStruct();
    window.console.log("[addContainer]", container);
    blocks.push(container);

    return container.id;
  }

  /**
   * Adds the default row to the given page
   * @param {Array} blocks - Array of blocks associated with the page
   * @param {String} id - identifier of the block
   * @returns {Array} Array of blocks
   */
  addRow(blocks: any, id: any): Array<any> {
    const block = BuilderUtility.findBlockById(blocks, id);

    if (!block.rows) {
      block.rows = [];
    }

    const row = this.getRowStruct();
    block.rows.push(row);

    window.console.log("blocks is now", blocks);
    return row.id;
  }

  /**
   * Adds the default columns to the given page
   * @param {Array} blocks - Array of blocks associated with the page
   * @param {String} id - identifier of the block
   * @returns {Array} Array of blocks
   */
  addColumn(blocks: any, id: any): Array<any> {
    window.console.log("blocks is", blocks);
    const block = BuilderUtility.find(blocks, "id", id);
    window.console.log("block is..", block);

    if (!block.cols) {
      block.cols = [];
    }
    block.cols.push(this.getColStruct());

    window.console.log("After adding the column, blocks is now", blocks);
    return blocks;
  }

  /**
   * Locates a block in a page by its given unique identiifer
   * @param {Array} blocks - Array of blocks associated with the page
   * @param {String} id - identifier of the block
   * @returns {Object|null} - Returns the found object, otherwise null
   */
  static getBlockById(blocks: any, id: any): object | null {
    const idKey = "id";
    return BuilderUtility.find(blocks, idKey, id);
  }

  /**
   * Creates a universally unique variable identifier in Firestore
   */
  createVariableId() {
    const variableRef = this.db.collection("variables").doc();

    return variableRef.id;
  }

  /**
   * Creates a block in a page by fetching each of the props associated with a given component
   * and creates a univerisally unique document identifier in Firestore for the given block
   * @param {String} funnelId - Identifier of the funnel
   * @param {String} pageId - Identifier of the page
   * @param {Array} blocks - Array of blocks associated with the page
   * @param {String} blockType  - Name of the block to create
   * @param {String} id - identifier of the block
   * @returns {Array} - Array of blocks
   */
  addBlock(blocks: any[], blockType: any, id: string, isRepeatable: boolean): Array<any> {
    const block = BuilderUtility.find(blocks, "id", id, false);
    const parentContainerId = BuilderUtility.findParentContainerId(blocks, id);

    const blockStruct = {
      ...this.getDefaultStruct(),
      type: blockType,
      parentContainerId: parentContainerId,
      ...JSON.parse(JSON.stringify(BuilderService.getProps(blockType))),
    };

    // Store as needs to be changed is page is repeatable
    if (isRepeatable && blockStruct.storeAs) {
      if (blockStruct.storeAs) {
        const storeProperty = {
          value: "",
          selectedVariable: "",
          required: "",
          type: "string",
          selectedAction: "newProperty",
          variableType: "",
          variableToCreate: "",
        };
        blockStruct.storeAs = storeProperty;
      }

      blockStruct.isRepeatableChild = true;
    }

    if (block.blocks.length > 1 && isRepeatable) {
      // Because repeatable page has buttons emebeded, add any new blocks 1 from last so buttons stay at bottom
      block.blocks.splice(block.blocks.length - 1, 0, blockStruct);
    } else {
      block.blocks.push(blockStruct);
    }

    return blockStruct.id;
  }

  getBlockStruct(blockType: any) {
    return {
      ...this.getDefaultStruct(),
      type: blockType,
      ...JSON.parse(JSON.stringify(BuilderService.getProps(blockType))),
    };
  }

  /**
   * Returns the array of available variable types
   * @returns {Array} Array of available variable types
   */
  getVariableTypes(): Array<any> {
    return [
      "string",
      "number",
      "boolean",
      "array",
      "Array<T>",
      "date",
      "file",
      "agreement",
      "object",
      "array of files",
      "T",
    ];
  }

  /**
   * Returns the an object containing each of a given component's props as keys
   * @param {String} component - Name of the component to fetch
   * @returns {Object} {k,v} prop map
   */
  static getProps(component: string): object {
    const builderPropService = new BuilderPropService();
    return builderPropService.getProps(component);
  }

  /**
   * If value is array, will join all values as a comma seperated string
   * @param {Array | String} vals
   * @returns { String }
   */
  concatArrayToString(vals: any[]): any {
    try {
      const isArray = Array.isArray(JSON.parse(JSON.stringify(vals)));

      if (isArray && vals.length > 1) {
        const last = vals.pop();
        return vals.join(", ") + last;
      } else if (isArray) {
        return vals[0];
      } else {
        return vals;
      }
    } catch {
      return vals;
    }
  }

  sortObjectByBlock(block: { [x: string]: any }, blockType: any) {
    const out = {};
    const blockProps = this.getBlockStruct(blockType);
    const blockPropsArray = Object.keys(blockProps);

    blockPropsArray.push("isRepeatableChild");

    blockPropsArray.forEach((key) => {
      out[key] = block[key];
    });

    return out;
  }
}

export { BuilderService };
