// General
import { getFirebaseBackend } from "@/authUtils";
import { AsyncHelper } from "../AsyncHelper";
// Types
import { firestore, StandardAsyncResult } from "@/types";
// Services
import { ClaimsGateErrors, ClaimsGateVariables, WorkspaceService } from "@claimsgate/core";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
import { PageHelper } from "@/helpers/ClaimsGate/funnels/PageHelper";
import { Claim, Workspace } from "@claimsgate/core-types";

export class UserService {
  db: firebase.default.firestore.Firestore;
  variableService: VariableService;

  constructor(db?: firestore) {
    if (db) {
      this.db = db;
    } else {
      this.db = getFirebaseBackend().firestore();
    }

    this.variableService = new VariableService(this.db);
  }
  /**
   * Returns the authenticated user
   * @returns { { email: string, phoneNumber: string , uid: string, [key: string] : any } }
   */
  getAuthenticatedUser(localStorage: Storage): { email: string; phoneNumber: string; uid: string; [key: string]: any } {
    const user = localStorage.getItem("auth.currentUser");

    if (!user) {
      return null;
    }

    return JSON.parse(user);
  }

  /**
   * Fetches the user from Firestore
   * @deprecated - Moved to function getUser in @claimsgate/core
   * @param userId
   * @returns
   */
  async getUser(userId: string) {
    if (!userId) {
      return AsyncHelper.onError(ClaimsGateErrors.UserIdMissing);
    }
    const userRef = this.db.collection("users").doc(userId);
    const userSnapshot = await userRef.get();
    if (!userSnapshot.exists) {
      return AsyncHelper.onError(ClaimsGateErrors.DataMissing);
    }
    const user = userSnapshot.data();

    return AsyncHelper.onCompleted(user);
  }

  /**
   * Fetches the workspace user from Firestore
   * @param userId
   * @returns
   */
  async getWorkspaceUser(workspaceId: string, userId: string) {
    if (!workspaceId) {
      return AsyncHelper.onError(ClaimsGateErrors.WorkspaceIdMissing);
    }
    if (!userId) {
      return AsyncHelper.onError(ClaimsGateErrors.UserIdMissing);
    }
    const userRef = this.db.collection("workspaces").doc(workspaceId).collection("users").doc(userId);
    const userSnapshot = await userRef.get();
    if (!userSnapshot.exists) {
      return AsyncHelper.onError(ClaimsGateErrors.DataMissing);
    }
    const user = userSnapshot.data();

    return AsyncHelper.onCompleted(user);
  }

  /**
   * Returns the authenticated user's userId
   */
  getAuthenticatedUserId(localStorage: Storage) {
    const user = this.getAuthenticatedUser(localStorage);

    if (user?.uid) {
      return user.uid;
    }

    return null;
  }

  /** Fetches the claims associated with a given user
   *  @note The returned claims data will be encrypted
   */
  async getClaims(userId): Promise<StandardAsyncResult> {
    try {
      if (!userId) {
        return AsyncHelper.onError(ClaimsGateErrors.UserIdMissing);
      }

      const userRef = this.db.collection("users").doc(userId);
      const claimsRef = userRef.collection("claims");
      const claimsSnapshot = await claimsRef.get();

      if (claimsSnapshot.empty) {
        return AsyncHelper.onError(ClaimsGateErrors.ClaimsMissing, userId);
      }

      const claims = claimsSnapshot.docs.map((document) => {
        return {
          ...document.data(),
          claimId: document.id,
        };
      });

      return AsyncHelper.onCompleted(claims);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /** Fetches the claims associated with a given user for a given workspace
   *  @note The returned claims data will be encrypted
   */
  async getUserWorkspaceClaims(userId: string, workspaceId: string): Promise<StandardAsyncResult> {
    try {
      if (!userId) {
        return AsyncHelper.onError(ClaimsGateErrors.UserIdMissing);
      }

      if (!workspaceId) {
        return AsyncHelper.onError(ClaimsGateErrors.WorkspaceIdMissing);
      }

      const userRef = this.db.collection("users").doc(userId);
      const claimsRef = userRef
        .collection("claims")
        .where("workspacesWithClaimDataAccess", "array-contains", workspaceId);
      const claimsSnapshot = await claimsRef.get();

      if (claimsSnapshot.empty) {
        return AsyncHelper.onError(ClaimsGateErrors.ClaimsMissing, userId);
      }

      const claims = claimsSnapshot.docs.map((document) => {
        return {
          ...document.data(),
          claimId: document.id,
        };
      });

      return AsyncHelper.onCompleted(claims);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /** Fetches the workspaces with which user has a claim
   *  @note The returned workspace data will be encrypted
   *  @note This function is specifically designed to cater for claims.vue
   */
  async getUserClaimWorkspaces(
    userId: string
  ): Promise<
    StandardAsyncResult<Array<Workspace>, ClaimsGateErrors.ClaimsMissing | ClaimsGateErrors.UserIdMissing, any>
  > {
    try {
      console.log("Starting to call getUserClaimWorkspaces");
      if (!userId) {
        return AsyncHelper.onError(ClaimsGateErrors.UserIdMissing);
      }

      const userRef = this.db.collection("users").doc(userId);
      const claimsRef = userRef.collection("claims");
      // It's returning a data set which exceeds the iOS limit of 10MB in browser

      const claimsSnapshot = await claimsRef.limit(50).get();

      if (claimsSnapshot.empty) {
        return AsyncHelper.onError(ClaimsGateErrors.ClaimsMissing, userId);
      }

      console.log("Before Crash 1");

      const claims = claimsSnapshot.docs.map((document) => {
        return {
          ...document.data(),
          claimId: document.id,
        };
      }) as Array<Claim>;

      console.log("Before Crash 2");

      // Filter out the claims which have not completed the start page or were created via API
      const validClaims = claims.filter((claim) => {
        return (
          (Array.isArray(claim.actions) &&
            claim.actions?.filter((action) => action.kind === "completion").length > 0) ||
          claim[ClaimsGateVariables.Tracking.utmSource.id] === "api"
        );
      });

      // convert all the claims into the data we need
      console.log("Before Crash 3");
      let workspaces = [];
      workspaces = await Promise.all(
        validClaims.map(async (claim) => {
          if (claim["workspacesWithClaimDataAccess"]) {
            if (claim["workspacesWithClaimDataAccess"][0] && claim["currentFunnelId"]) {
              return {
                workspaceId: claim["workspacesWithClaimDataAccess"][0],
                lastUpdated: claim["lastUpdated"] ?? claim["updatedAt"],
                isComplete: await this.isClaimComplete(claim),
              };
            }
          }

          return null;
        })
      );

      // remove all null workspace values (those that didn't have workspacesWithClaimDataAccess))
      workspaces = workspaces.filter((workspace) => workspace != null);

      // sort based on workspaceId and then on date of lastUpdated
      workspaces.sort((a, b) => a.workspaceId.localeCompare(b.workspaceId) || b.lastUpdated - a.lastUpdated);

      // reduce workspaces to remove repeated while couting number of incomplete claims

      console.log("Before Crash 4");
      const uniqueWorkspaces = {};
      for (let i = 0; i < workspaces.length; i++) {
        if (!uniqueWorkspaces[workspaces[i].workspaceId]) {
          uniqueWorkspaces[workspaces[i].workspaceId] = {};
          if (!workspaces[i].isComplete) {
            workspaces[i].incompleteClaims = 1;
          } else {
            workspaces[i].incompleteClaims = 0;
          }
        } else {
          if (!workspaces[i].isComplete) {
            workspaces.find((workspace) => workspace.workspaceId === workspaces[i].workspaceId).incompleteClaims++;
          }
          workspaces.splice(i, 1);
          i--;
        }
      }

      console.log("Before Crash 5");
      const workspaceService = new WorkspaceService(getFirebaseBackend().firebase());
      workspaces = await Promise.all(
        workspaces.map(async (workspace) => {
          const { data: workspaceData } = await workspaceService.getWorkspace(workspace.workspaceId);
          return { workspaceData, lastUpdated: workspace.lastUpdated, incompleteClaims: workspace.incompleteClaims };
        })
      );

      return AsyncHelper.onCompleted(workspaces);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /** Validates whether a claim exists in Claims Gate */
  async validateClaimExists(userId: string, claimId: string) {
    try {
      if (!userId) {
        return AsyncHelper.onError(ClaimsGateErrors.UserIdMissing);
      }
      if (!claimId) {
        return AsyncHelper.onError(ClaimsGateErrors.ClaimDoesNotExist);
      }

      const userRef = this.db.collection("users").doc(userId);
      const claimRef = userRef.collection("claims").doc(claimId);
      const claimSnapshot = await claimRef.get();

      return AsyncHelper.onCompleted(claimSnapshot.exists);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /** Updates a user document with the given stores */
  async updateUser(userId: string, stores: firebase.default.firestore.UpdateData) {
    try {
      if (!userId) {
        return AsyncHelper.onError(ClaimsGateErrors.UserIdMissing);
      }

      const userRef = this.db.collection("users").doc(userId);

      await userRef.update(stores);

      return AsyncHelper.onCompleted(true);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /** Updates a claim with the exact stores given. This does not support hashing variables, feel free to extend this.*/
  async updateClaim(claimId: string, stores: firebase.default.firestore.UpdateData) {
    try {
      // Find the claim with the given identifier
      const claimQueryRef = this.db.collectionGroup("claims").where("documentId", "==", claimId);
      const claimQuerySnapshot = await claimQueryRef.get();

      // Check if the claim exists
      if (claimQuerySnapshot.empty) {
        return AsyncHelper.onError(ClaimsGateErrors.ClaimDoesNotExist);
      }
      const claimRef = claimQuerySnapshot.docs[0].ref;

      await claimRef.update(stores);
      // ! This function does not hash variables

      return AsyncHelper.onCompleted(true);
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  async getClaim(
    userId,
    claimId
  ): Promise<StandardAsyncResult<any, ClaimsGateErrors.UserIdMissing | ClaimsGateErrors.ClaimIdMissing>> {
    try {
      if (!userId) {
        return AsyncHelper.onError(ClaimsGateErrors.UserIdMissing);
      }

      if (!claimId) {
        return AsyncHelper.onError(ClaimsGateErrors.ClaimIdMissing);
      }
      const userRef = this.db.collection("users").doc(userId);
      const claimRef = userRef.collection("claims").doc(claimId);
      const claimSnapshot = await claimRef.get();

      if (!claimSnapshot.exists) {
        return AsyncHelper.onError(ClaimsGateErrors.ClaimDoesNotExist);
      }

      return AsyncHelper.onCompleted(claimSnapshot.data());
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  async getClaimData(
    userId,
    claimId
  ): Promise<StandardAsyncResult<any, ClaimsGateErrors.UserIdMissing | ClaimsGateErrors.ClaimIdMissing>> {
    try {
      if (!userId) {
        return AsyncHelper.onError(ClaimsGateErrors.UserIdMissing);
      }

      if (!claimId) {
        return AsyncHelper.onError(ClaimsGateErrors.ClaimIdMissing);
      }
      const userRef = this.db.collection("users").doc(userId);
      const claimRef = userRef.collection("claims").doc(claimId);
      const claimSnapshot = await claimRef.get();

      if (!claimSnapshot.exists) {
        return AsyncHelper.onError(ClaimsGateErrors.ClaimDoesNotExist);
      }

      return AsyncHelper.onCompleted(claimSnapshot.data());
    } catch (exception) {
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Calculates if the funnel has been completed using the claim's current page and trail
   * ! This is a copy from track.vue - currently not working
   */
  async isClaimComplete(claimData): Promise<boolean> {
    // ! The emissions and tesco funnels were created before we required the final page
    // ! in a funnel to be completed before we determined it to be completed.
    // ! So we will not consider if the final page has been submitted if the claim's currentFunnelId
    // ! matches the below
    const funnelsToIgnore = ["ja6Zeq12PfLqWlwOdgc3", "AYGlaP5d05oFw8vJIhPv"];

    if (claimData.trail) {
      const lastTrail = claimData.trail[claimData.trail.length - 1];
      // Fetch the page meta data
      const funnelId = claimData.currentFunnelId;
      const pageId = claimData.currentPageId;

      // if (!funnelId) {
      //   ({ funnelId } = lastTrail);
      // }
      // if (!pageId) {
      //   ({ pageId } = lastTrail);
      // }

      const pageService = new PageHelper();
      const { data: pageMetadata } = await pageService.getPageMeta(funnelId, pageId);

      if (pageMetadata?.next?.pageId === "None") {
        if (funnelsToIgnore.includes(claimData?.currentFunnelId)) {
          return true;
        }

        return claimData?.trail?.filter((trialElement) => trialElement.pageId === claimData.currentPageId).length > 0;
      }
    }
    return false;
  }
}
