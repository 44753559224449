/**
 * Combines the funnelId and slug into the appropriate format for the funnelId path param
 */
export function buildFunnelParamSlug(funnelId: string, slug: string): string {
  if (slug?.length > 0) {
    return `${slug}-${funnelId}`;
  } else {
    return funnelId;
  }
}
