import VueRouter, { RouteConfig } from "vue-router";

/**
Core features:
  Pages are made up of blocks
    Blocks can contain computes which may respond with a new route (this would be their next page to view)
  Pages may also just be associated with a property 'next' which is a route (this would be their next page to view)

A method would call your router to tell you there is a new route

You must be able to:
  - Track the previous routes
  - Accept a new route
  - Check if the current route is the last step (by doing a compairson against last fetched route)
    => You can assume we will always call 'getNextStep'
 */

type SectionRoute = { pageId: any; funnelId: any };

interface RouterStack {
  push(route: SectionRoute): void;
  pop(): SectionRoute | undefined;
  peek(): SectionRoute | undefined;
  isEmpty(): boolean;
}

class RouterStackArrayImpl implements RouterStack {
  private routes: SectionRoute[] = [];

  push(route: SectionRoute): void {
    this.routes.push(route);
  }

  pop(): SectionRoute | undefined {
    if (this.isEmpty()) {
      return undefined;
    }
    return this.routes.pop();
  }

  peek(): SectionRoute | undefined {
    if (this.isEmpty()) {
      return undefined;
    }
    return this.routes[this.routes.length - 1];
  }

  isEmpty(): boolean {
    return this.routes.length === 0;
  }
}

/**
 * We will be using a doubly linked list
 * to keep track of the pages we have
 * visted (Breadcrumb)
 */
export interface RouteListNode<T> {
  route: T;
  next: RouteListNode<T> | undefined;
  prev: RouteListNode<T> | undefined;
}

/**
 */
export interface RouteComparatorFunction<T> {
  /**
   * @param route The ro
   */
  (route: T): boolean;
}

/**
 * Linked list interface
 */
export interface RoutesLinkedList<T> {
  /**
   * Inserts a route into the beginning of
   * this list
   * @param route The route to insert
   * @returns Returns the node that was inserted
   */
  insertAtBegin(route: T): RouteListNode<T> | undefined;

  /**
   * Inserts a route into the end of
   * this list
   * @param route The route to insert
   * @returns Returns the node that was inserted
   */
  insertAtEnd(route: T): RouteListNode<T> | undefined;

  /**
   * Deletes a node from the list
   * @param node The node to delete
   */
  deleteRouteListNode(node: RouteListNode<T>): void;

  /**
   * Traverse the list
   * @returns Returns an array of the routes
   *          in the list
   */
  traverse(): T[];

  /**
   * Clears the list
   */
  clear(): void;

  /**
   * Return the number of items
   * in the list
   * @returns The number of items in the list
   */
  size(): number;

  /**
   * Finds and returns an item in the list
   * @returns Returns the node or undefined otherwise
   */
  search(comparator: RouteComparatorFunction<T>): RouteListNode<T> | undefined;

  /**
   * Get the last items value
   * @returns Returns the value of the last item in the list
   */
  getLast(): RouteListNode<T> | undefined;
}

export class StringRouteLinkedListImpl implements RoutesLinkedList<SectionRoute> {
  private head: RouteListNode<SectionRoute> | undefined;
  /**
   * Inserts a route into the beginning of
   * this list
   * @param route The route to insert
   * @returns Returns the node that was inserted
   */
  insertAtBegin(route: SectionRoute): RouteListNode<SectionRoute> {
    const node: RouteListNode<SectionRoute> = { route, prev: undefined, next: undefined };
    if (!this.head) {
      this.head = node;
    } else {
      this.head.prev = node;
      node.next = this.head;
      this.head = node;
    }
    return node;
  }

  /**
   * Inserts a route into the end of
   * this list
   * @param route The route to insert
   * @returns Returns the node that was inserted
   */
  insertAtEnd(route: SectionRoute): RouteListNode<SectionRoute> {
    const node: RouteListNode<SectionRoute> = { route, prev: undefined, next: undefined };
    if (!this.head) {
      this.head = node;
    } else {
      const lastNode = this.getLastNode(this.head);
      node.prev = lastNode;
      lastNode.next = node;
    }
    return node;
  }

  /**
   * Deletes a node from the list
   * @param node The node to delete
   */
  deleteRouteListNode(node: RouteListNode<SectionRoute>): void {
    if (!node.prev) {
      this.head = node.next;
    } else {
      const prevNode = node.prev;
      prevNode.next = node.next;
    }
  }
  /**
   * Clears the list
   */
  clear(): void {
    if (this.head) {
      this.head = undefined;
    }
  }

  /**
   * Traverse the list
   * @returns Returns an array of the routes
   *          in the list
   */
  traverse(): SectionRoute[] {
    const routesArray: SectionRoute[] = [];
    if (!this.head) {
      return routesArray;
    }
    const addToArrayFunc = (node: RouteListNode<SectionRoute>): SectionRoute[] => {
      routesArray.push(node.route);
      return node.next ? addToArrayFunc(node.next) : routesArray;
    };
    return addToArrayFunc(this.head);
  }

  /**
   * Return the number of items
   * in the list
   * @returns The number of items in the list
   */
  size(): number {
    return this.traverse().length;
  }

  /**
   * Finds and returns an item in the list
   * @returns Returns the node or undefined otherwise
   */
  search(comparator: RouteComparatorFunction<SectionRoute>): RouteListNode<SectionRoute> | undefined {
    const checkNext = (node: RouteListNode<SectionRoute>): RouteListNode<SectionRoute> | undefined => {
      if (comparator(node.route)) {
        return node;
      }
      return node.next ? checkNext(node.next) : undefined;
    };
    return this.head ? checkNext(this.head) : undefined;
  }

  /**
   * Get the last items value
   * @returns Returns the value of the last item in the list
   */
  getLast(): RouteListNode<SectionRoute> | undefined {
    if (!this.head) {
      return undefined;
    } else {
      const getLastListNode = (node: RouteListNode<SectionRoute>): RouteListNode<SectionRoute> | undefined => {
        return node.next ? getLastListNode(node.next) : node;
      };
      return getLastListNode(this.head);
    }
  }
  /**
   * Use recursion to get last node
   */
  protected getLastNode(node: RouteListNode<SectionRoute>): RouteListNode<SectionRoute> {
    return node.next ? this.getLastNode(node.next) : node;
  }
}

/**
 * Basic router
 */
export class Router {
  private static instance: Router;
  private lastRoute: SectionRoute | undefined;
  private routes: RoutesLinkedList<SectionRoute> = new StringRouteLinkedListImpl();

  private constructor() {}

  /**
   * Initialize the routes list
   * with the given routes
   * @param trail The routes to initialize list
   */
  loadRoutes(trail: SectionRoute[]): void {
    if (trail) {
      this.routes.clear();
      trail.forEach((route: SectionRoute) => {
        this.acceptNewRoute(route);
      });
    }
  }

  /** Creates a new instance of the Router */
  public static createInstance(): Router {
    Router.instance = new Router();
    return Router.instance;
  }

  /**
   * getInstance
   */
  public static getInstance() {
    if (!Router.instance) {
      Router.instance = new Router();
    }
    return Router.instance;
  }

  /**
   * @param route New route to accept
   */
  acceptNewRoute(route: SectionRoute) {
    const lastNode = this.routes.getLast();
    if (lastNode) {
      this.lastRoute = lastNode.route;
    }
    this.routes.insertAtEnd(route);
  }

  /**
   * Returns the route of the previously rendered page
   */
  getPreviousRoute(routeId: string): object | undefined {
    if (this.routes.size() === 0) {
      return undefined;
    }

    const routes = this.routes.traverse();

    const currentRouteIndex = routes.findIndex((route) => route.pageId === routeId);
    console.log("Routes is!", routes, currentRouteIndex, routeId);
    if (currentRouteIndex !== -1 && currentRouteIndex !== 0) {
      return routes[currentRouteIndex - 1];
    }

    return undefined;
  }

  /**
   * Returns the previous route and splices all routes after the previous route
   * to remain consistent
   * @param routeId
   * @returns {Object} - Previous route
   */
  getPreviousRouteAndSplice(routeId: string): object | undefined {
    if (this.routes.size() === 0) {
      return undefined;
    }

    const routes = this.routes.traverse();

    const currentRouteIndex = routes.findIndex((route) => route.pageId === routeId);

    routes.splice(currentRouteIndex + 1, Infinity);

    console.log("routes is: ", routes);
    if (currentRouteIndex !== -1 && currentRouteIndex !== 0) {
      return routes[currentRouteIndex - 1];
    }

    return undefined;
  }

  /**
   *
   * @returns The list of routes which have been navigated
   */
  getBreadCrumbs() {
    return this.routes.traverse();
  }

  /**
   * @return
   */
  getNextRoute(): { funnelId: string; pageId: string } | undefined {
    const lastNode = this.routes.getLast();
    if (lastNode) {
      return lastNode.route;
    }
    return undefined;
  }

  /**
   * @return boolean True if we have next route and false otherwise
   */
  hasNextRoute(): boolean {
    // I remove the || !this.lastRoute
    if (this.routes.size() === 0) {
      return false;
    }

    return (this.lastRoute as any) !== this.routes.getLast();
  }
}
