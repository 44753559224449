import { v4 as uuidv4 } from "uuid";
/**
 * Conditional Mixin provides a series of helper methods which can help add conditionals
 * to a page in Claims Gate
 * @param {*} superclass
 * @returns
 */
export const conditionalMixin = superclass =>
  class extends superclass {
    /**
     * Adds a new conditional to the page
     */
    addConditional(conditionals, group) {
      var type;
      if (group) {
        type = "group";
      } else {
        type = "";
      }

      const conditionalStruct = {
        id: uuidv4(),
        title: "Untitled",
        routeType: "",
        type: type,
        field: "",
        actual: "",
        values: "",
        conditionals: [{ type: "", field: "", actual: "", values: "" }],
        next: {
          pageId: "",
          funnelId: "",
          meta: {
            selectedRouteType: "",
            funnelSearchId: "",
            funnelSearchResult: { author: "", title: "", id: "" },
            isFunnelLinkError: false,
            isFunnelSearchError: false
          }
        },
        captureFields: []
      };

      conditionals.push(conditionalStruct);

      return conditionalStruct;
    }
    /**
     * Removes a conditional at a given index from a page
     */
    deleteConditional(conditionals, conditionalIndex) {
      conditionals.splice(conditionalIndex, 1);
    }

    getRouteTypes() {
      return ["PageRoute", "FunnelRoute"];
    }
  };
