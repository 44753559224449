import { PageHelper } from "@/helpers/ClaimsGate/funnels/PageHelper";
import { getConditionalProcessor } from "@/helpers/ClaimsGate/conditionals/ConditionalProcessor";
import { Block, Page } from "@claimsgate/core-types";
export class PageValidationService {
  private conditionalProcessor = getConditionalProcessor();
  /**
   * Executes each of the validations in this page
   */
  async runValidations(page: Page, claimId: string): Promise<{ error?: any; exception?: any; result?: boolean }> {
    // Check if there are conditionals for evaluation
    const { validations } = JSON.parse(JSON.stringify(page));

    if (validations) {
      const validationErrors = [];
      let validationExceptions;

      for (const validation of validations) {
        // Load in the unparsed version of actual
        let { actual } = validation;
        const { type } = validation;

        // If the answer is collected on this page and the block is not visible then skip validation
        const shouldSkipValidation = page.blocks.some(
          (block: Block) => block?.storeAs?.id === actual.id && !block.isVisible
        );

        if (shouldSkipValidation) {
          continue;
        }

        // Execute group conditionals
        let fields;

        if (type === "group") {
          fields = [];
          await Promise.all(
            validation.conditionals.map(async (groupConditional, groupConditionalIndex) => {
              // Parse group conditionals
              const { actual } = groupConditional;
              const { id, field } = actual;

              validation.conditionals[groupConditionalIndex].actual = await PageHelper.loadVariable(page, id, claimId);

              console.log(">> actual", actual);
              // Append field if we need to display an error message
              fields.push(field);
              return true;
            })
          );
        }

        const { id, field } = JSON.parse(JSON.stringify(actual));

        console.log("[validation] load variable");
        actual = PageHelper.loadVariable(page, id, claimId);
        console.log("[validation] load variable", actual);

        const { error } = await this.conditionalProcessor.execute({
          ...validation,
          actual,
        });

        console.log(">> Validation to run, Result", validation, actual);
        if (error) {
          if (fields && fields.length > 0) {
            validationErrors.push({ message: error, field: fields });
            // return { error: { message: error, field: fields } };
          } else {
            validationErrors.push({ message: error, field: field });
            // return { error: { message: error, field: field } };
          }
        }
      }

      if (validationExceptions || validationErrors.length > 0) {
        return { error: validationErrors, exception: validationExceptions ?? undefined };
      } else {
        return { result: true };
      }
    }
    return { result: true };
  }
}
