import { Variable } from "@claimsgate/core-types";
import { MutatorFunction } from "@/types/vue/Form";
import { Container, Page, Row, Column, Block } from "@claimsgate/core-types";
import { UserDataService, ClaimDataService } from "../DataService";
import { DateService } from "../DateService";
import { getUserHelper } from "../UserHelper";

export class PageUtility {
  /**
   * Executes an asynchronous mutator function on a given depth in a page
   * @param mutator - Function to execute at the given depth
   * @param page - Object to enumerate
   * @param depth - Depth to view
   * @returns {boolean}
   */
  static async traverseBlocks(
    mutator: MutatorFunction<Promise<void>>,
    page: Page,
    depth: "cols" | "container" | "blocks" = "cols"
  ): Promise<boolean> {
    if (!page) {
      return false;
    }

    const { blocks } = page;
    const blockPromises: Promise<void>[] = [];
    if (depth === "cols" || depth === "blocks") {
      blocks.forEach((block: Block) => {
        blockPromises.push(mutator(block));
      });
    } else if (depth === "container") {
      blocks.forEach((container: Container) => {
        blockPromises.push(mutator(container));
      });
    }
    // Wait for all asynchronous mutators to finish executing
    await Promise.all(blockPromises);

    return true;
  }

  /**
   * Executes a synchronous mutator function on a given depth in a page
   * @param mutator - Function to execute at the given depth
   * @param page - Object to enumerate
   * @param depth - Depth to view
   * @returns {boolean}
   */
  static traverseBlocksSync(
    mutator: MutatorFunction<void>,
    page: Page,
    depth: "blocks" | "container" | "cols" = "cols"
  ) {
    if (!page) {
      return false;
    }
    console.log("[traverseSync] with ", JSON.parse(JSON.stringify(page)));
    const { blocks } = page;
    const result = [];
    if (depth === "cols" || depth === "blocks") {
      blocks.forEach((block: Block) => {
        const blockResult = mutator(block);
        result.push(blockResult);
      });
    } else if (depth === "container") {
      blocks.forEach((container: Container) => {
        const blockResult = mutator(container);
        result.push(blockResult);
      });
    }
    // Wait for all asynchronous mutators to finish executing
    return result;
    // return true;
  }

  /** Finds the block holding a variable in the page */
  static findVariableWithBlock(page: Page, field: string) {
    let block: Block = null;

    // Loop through each of the blocks in the page and check
    // if the block.storeAs.field matches the field we are
    // attempting to find
    const mutator: MutatorFunction<void> = (_block: Block) => {
      if (_block.storeAs?.field === field || _block.storeAs?.id === field) {
        console.log("[FindVariableWithBlock] matching: ", _block);
        block = _block;
      }
    };

    this.traverseBlocksSync(mutator, page);

    return block;
  }

  /***
   * Scans each of the blocks in a page for a given variable
   */
  static findVariableInPage(page, field): any {
    let block: Block;

    // Loop through each of the blocks in the page and check
    // if the block.storeAs.field matches the field we are
    // attempting to find
    const mutator: MutatorFunction<void> = (_block: Block) => {
      if (_block.storeAs?.field === field || _block.storeAs?.id === field) {
        block = _block;
      }
    };

    this.traverseBlocksSync(mutator, page);

    console.log("[debug] Trying to find variable in page", block, field);
    if (block) {
      const { answer } = block;

      if (typeof answer === "object" && answer?.[field]) {
        return answer[field];
      } else {
        return answer;
      }
    }

    return null;
  }

  /**
   * Loads in a variable
   * @returns The value associated with the loaded in variable or null
   */
  static loadVariable(page: Page, id: string, claimId: string, property: string = null, iteration: number = null) {
    if (id === "9fBZs7pqcPXh0UG8xttO") {
      id = "claimId";
    }

    if (id === "V0CMip9GOy0kb1hUobNB") {
      id = "userId";
    }

    const userService = getUserHelper();
    const userDataService = UserDataService.getInstance(userService.getUserId());
    let claimDataService: ClaimDataService;
    if (claimId) {
      claimDataService = ClaimDataService.getInstance(claimId);
    }

    const dateService = new DateService();

    let userData;
    let claimData;

    if (userDataService) {
      userData = userDataService.getCache();
    }
    if (claimDataService) {
      claimData = claimDataService.getCache();
    }
    // User data takes precedence!

    if (userData && userData[id]) {
      let value = userData[id];

      if (dateService.parseDate(value) instanceof Date) {
        value = dateService.parseDate(value).toISOString();
      }

      return value;
    }

    // If the variable is collected in the page and a value has been given then
    // we pull it from the current page first
    const variableCollectedInPage = PageUtility.findVariableInPage(page, id);

    if (variableCollectedInPage !== undefined && variableCollectedInPage !== null && variableCollectedInPage !== "") {
      return variableCollectedInPage;
    }

    // If we can not find the variable in the claim data
    if (!claimData || !claimData[id]) {
      return variableCollectedInPage;
    }
    let value = claimData[id];

    // If we are loading an element from an array of objects, we use the current iteration value and property
    if (property) {
      value = value[iteration][property];
    }

    if (dateService.parseDate(value) instanceof Date) {
      value = dateService.parseDate(value).toISOString();
    }
    return value;
  }
}
