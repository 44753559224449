import urlParse from "url-parse";
/**
 * Builder Utility maintains a list of methods which assist BuilderService.js
 */
class BuilderUtility {
  static getProps = () => {
    window.console.log("fj");
  };

  static parseBlock(block, isEditor = false) {
    const out = {};

    const keysToExclude = ["description", "isValid"];
    Object.keys(block).forEach(blockKey => {
      if (blockKey === "type") {
        out[blockKey] = block[blockKey];
      }

      if (block[blockKey] && block[blockKey].value !== undefined && !keysToExclude.includes(blockKey)) {
        // Props which are loaded in as variables can be displayed as
        // @variableName

        if (isEditor) {
          if (typeof block[blockKey].value === "object" && !Array.isArray(block[blockKey].value)) {
            if (block[blockKey].value) {
              const { field } = block[blockKey].value;
              if (block[blockKey].type === "string") {
                out[blockKey] = "@" + field;
              } else if (block[blockKey].type === "array") {
                window.console.log("why u hit me tho? with", block, blockKey);
                out[blockKey] = [`@${field}`];
              }
            }
          } else {
            out[blockKey] = block[blockKey].value;
          }
        } else {
          out[blockKey] = block[blockKey].value;
        }
      }
    });
    return out;
  }

  /**
   * Gets the list of available blocks for creation in a page
   * @returns {Array} Array of block available block names
   */
  static getAvailableBlocks() {
    return Object.keys(global.vm.$options.components);
  }
  static findBlockById(blocks, id) {
    return blocks.find(block => block.id === id);
  }

  static find(collection, key, value, returnArray = false) {
    for (const o of collection) {
      for (const [k, v] of Object.entries(o)) {
        if (k === key && v === value) {
          if (returnArray) {
            return collection;
          } else {
            return o;
          }
        }
        if (Array.isArray(v)) {
          const _o = this.find(v, key, value, returnArray);
          if (_o) {
            if (returnArray) {
              return _o;
            } else {
              return _o;
            }
          }
        }
      }
    }
  }

  /**
   * Finds the unique identiifer of a column's parent i
   * @param {Array} blocks
   * @param {String} columnId
   * @returns
   */
  static findParentContainerId(blocks, columnId) {
    let result;
    window.console.log("[findParent] blocks is", blocks);
    blocks.forEach(container => {
      container.rows.forEach(row => {
        row.cols.forEach(column => {
          if (column.id === columnId) {
            result = container.id;
          }
        });
      });
    });

    return result;
  }

  /**
   * Parses a relative link/URL into an absolute URL by appending the protocol aka scheme
   */
  static parseUrl(url) {
    let parsedUrl = urlParse(url, {});
    if (!parsedUrl.protocol) {
      parsedUrl.set("protocol", "https");
    }

    return parsedUrl.href;
  }
}
export { BuilderUtility };
