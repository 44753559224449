type InputExtraProps =
  | "padding"
  | "elementId"
  | "linkText"
  | "linkTarget"
  | "linkTargets"
  | "linkTexts"
  | "text"
  | "other"
  | "optionsCSV"
  | "options"
  | "placeholder"
  | "inputType"
  | "align"
  | "block"
  | "textRows"
  | "maxlength"
  | "autocomplete"
  | "name"
  | "requireConfirm"
  | "enforceValidation"
  | "enforceVerification"
  | "workspacePrivacyPolicyLink"
  | "workspaceTermsAndConditionsLink"
  | "required"
  | "condition"
  | "label"
  | "info"
  | "state"
  | "privacyPolicyText"
  | "privacyPolicyLink"
  | "smsCheckBox"
  | "invalidFeedback"
  | "hintText"
  | "hintIcon"
  | "sslBadge";

interface InputPropOptions {
  // Set the answer type
  answerType?: "string" | "array" | "object" | "date" | "none";

  blockType?: string;
  // Select extra props to be used
  extraProps?: Array<InputExtraProps>;
  // overide the default description of any prop
  descriptions?: { [key: string]: string };

  placeholder?: string;

  excludeDefaults?: boolean;

  autocomplete?: string;
  name?: string;
}
// Default props used in all Input blocks
const _defaults = {
  padding: {
    type: String,
    required: false,
    default: "10",
    description: "The padding above the block",
  },
  componentClass: {
    type: String,
    required: false,
    default: "",
    description: "The class of the input component",
  },
  labelClass: {
    type: String,
    required: false,
    default: "text-black",
    description: "The class of the label",
  },
  infoClass: {
    type: String,
    required: false,
    default: "text-black",
    description: "The class of the info text",
  },
  label: {
    type: String,
    required: false,
    description: "The label on the input",
  },
  info: {
    type: String,
    required: false,
    allowVariable: false,
    description: "The help description surrounding the input",
  },
  invalidFeedback: {
    type: String,
    required: false,
    allowVariable: false,
    default: "This field is required",
    description: "The feedback displayed when an input is invalid",
  },
  state: {
    type: Boolean,
    required: false,
    allowVariable: true,
    default: null,
    description:
      "Controls the validation state appearance of the component. `true` for valid, `false` for invalid, or `null` for no validation state",
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
    description: "Disable the input so the user cannot edit the value",
  },
  required: {
    type: Boolean,
    required: false,
    default: true,
    description: "Require the user to complete this field",
    allowVariable: false,
  },
  condition: {
    type: Object,
    required: false,
    default: () => {
      return {
        actual: {},
        values: [],
        value: "",
        type: "",
      };
    },
  },
};

// Extra props used in some Input blocks
export const extraProps = {
  padding: {
    type: String,
    required: false,
    default: "10",
    description: "The padding above the block",
  },
  elementId: {
    type: String,
    required: false,
    default: "input",
    allowVariable: true,
    description: "The id of the input",
  },
  options: {
    type: Array,
    required: false,
    allowVariable: true,
    // If the options are exactly the same, causes bugs in component
    default: () => ["Yes", "No"],
    description: "The options to display",
  },
  placeholder: {
    type: String,
    required: false,
    allowVariable: true,
    default: "This is the placeholder",
    description: "The placeholder in the input",
  },
  inputType: {
    type: String,
    required: true,
    default: "text",
    valid: ["text", "number", "email", "phoneNumber"],
    description: "The type of the input",
    allowVariable: false,
  },
  block: {
    type: Boolean,
    required: true,
    default: false,
    allowVariable: false,
  },
  align: {
    type: String,
    required: true,
    allowVariable: false,
    default: "center",
    valid: ["left", "center", "right"],
    description: "The alignment of text to display",
  },
  other: {
    type: Boolean,
    required: false,
    default: false,
    description: "If true, 'Other' option will be available in dropdown, to allow user to enter custom string",
  },

  optionsCSV: {
    type: Array,
    required: false,
    allowVariable: false,
    description: "CSV file to be parsed into an array for dropdown options",
    default: () => [],
  },

  linkText: {
    type: String,
    required: false,
    allowVariable: true,
    default: "Privacy Policy",
    description: "The linked text to display on the checkbox",
  },

  linkTarget: {
    type: String,
    required: false,
    allowVariable: true,
    default: "https://portal.claimsgate.co.uk/",
    description: "The URL target of the link text",
  },
  text: {
    type: String,
    required: false,
    allowVariable: true,
    default: "I agree to the",
    description: "The text to display",
  },
  textRows: {
    type: String,
    required: false,
    allowVariable: false,
    default: "4",
    valid: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "15", "20"],
    description: "The starting number of rows of a text area",
  },
  maxlength: {
    type: String,
    required: false,
    allowVariable: true,
    description: "Maximum length of input",
  },
  autocomplete: {
    type: String,
    required: false,
    allowVariable: false,
    description: "Autocomplete settings",
  },
  name: {
    type: String,
    required: false,
    allowVariable: false,
    description: "Name of the input field",
  },
  requireConfirm: {
    type: Boolean,
    required: false,
    default: false,
    allowVariable: false,
    description: "Force the user to enter email address twice.",
  },
  enforceValidation: {
    type: Boolean,
    required: false,
    default: false,
    description: "If true, the provided email will be validated to be real and active",
  },
  enforceVerification: {
    type: Boolean,
    required: false,
    default: false,
    description: "If true, the user will have to prove they have owership",
  },
  workspacePrivacyPolicyLink: {
    type: String,
    required: false,
    default: "",
    description: "The link to the privacy policy of the workspace this funnel belongs to.",
  },
  workspaceTermsAndConditionsLink: {
    type: String,
    required: false,
    default: "",
    description: "The URL of the workspaces term and conditions",
  },
  required: {
    type: Boolean,
    required: false,
    default: true,
    description: "Require the user to complete this field",
    allowVariable: false,
  },
  condition: {
    type: Object,
    required: false,
    default: () => {
      return {
        actual: {},
        values: [],
        value: "",
        type: "",
      };
    },
  },
  label: {
    type: String,
    required: false,
    description: "The label on the input",
  },
  hintText: {
    type: String,
    required: false,
    allowVariable: true,
    default: "",
    description: "The hint text to display",
  },
  hintIcon: {
    type: String,
    required: false,
    allowVariable: true,
    default: "",
    description: "The icon to display",
  },
  sslBadge: {
    type: Boolean,
    required: false,
    default: false,
    description: "If true, a SSL badge will be displayed",
  },
  info: {
    type: String,
    required: false,
    allowVariable: false,
    description: "The help description surrounding the input",
  },
  state: {
    type: Boolean,
    required: false,
    allowVariable: true,
    default: null,
    description:
      "Controls the validation state appearance of the component. `true` for valid, `false` for invalid, or `null` for no validation state",
  },
  privacyPolicyText: {
    type: String,
    default: "I agree to the",
    required: false,
  },
  privacyPolicyLink: {
    type: String,
    default: "https://claimsgate.co.uk/legal/privacy",
    required: false,
  },
  smsCheckBox: {
    type: Boolean,
    required: false,
    default: false,
    description: "If true, a checkbox will be displayed to allow the user to opt in to SMS",
  },
  invalidFeedback: {
    type: String,
    required: false,
    allowVariable: false,
    default: "This field is required",
    description: "The feedback displayed when an input is invalid",
  },
};

/**
 * Function that creates the commomn prop objects for input blocks
 * @param options declare answer types, extra props and custom descriptions
 * @returns Prop object to be used in vue component
 */
export function inputProps(options: InputPropOptions) {
  let defaults: Partial<typeof _defaults & { answer: any; placeholder: any }> = {};
  if (!options.excludeDefaults) {
    defaults = { ..._defaults };
  }
  // Set answer type
  if (options.answerType === "string") {
    defaults.answer = {
      type: String,
      required: false,
      allowVariable: false,
      default: "",
      description: "The selected answer",
    };
  } else if (options.answerType === "array") {
    defaults.answer = {
      type: [Array, String],
      required: false,
      allowVariable: false,
      default: () => [],
      description: "The selected answer(s)",
    };
  } else if (options.answerType === "date") {
    defaults.answer = {
      required: false,
      allowVariable: false,
      default: "",
      description: "The selected answer",
    };
  }

  // Add optional extras to the return object
  if (options.extraProps) {
    options.extraProps.forEach((key) => {
      defaults[key] = extraProps[key];
    });
  }

  // Overide any descriptions
  if (options.descriptions) {
    Object.keys(options.descriptions).forEach((key) => {
      defaults[key].description = options.descriptions[key];
    });
  }

  if (options.placeholder && defaults.placeholder) {
    defaults.placeholder.default = options.placeholder;
  }

  return defaults as any;
}

export function getInputDefaults(keys: Array<string>) {
  const out = {};
  keys.forEach((key) => {
    out[key] = _defaults[key];
  });

  return out;
}

export function inputWrapperProps() {
  const props = JSON.parse(JSON.stringify(_defaults));

  delete props.disable;
  delete props.required;
  delete props.value;
  return props;
}
