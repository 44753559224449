import { DateService } from "../DateService";
import { DateTime } from "@claimsgate/core/src/forwards/luxon/DateTime";

export const conditionalList = {
  equals,
  doesNotEqual,
  greaterThan,
  lessThan,
  exists,
  doesNotExist,
  contains,
  doesNotContain,
};
// Create a function which can remove all whitespace from a given string
function removeWhitespace(str: string): string {
  return str.replace(/\s/g, "");
}

/** Returns a boolean indicating if expected and actual have potential to be of the same type */
export function checkTypeEquality<T1, T2>(expected: T1, actual: T2): boolean {
  const dateService = new DateService();

  if (actual instanceof Date) {
    // If the expected and actual are both dates
    if (dateService.parseDate(expected) instanceof Date) {
      return true;
    } else {
      // If the actual is a date and the expected is not
      return false;
    }
  }

  // If the type of expected and actual are not the same
  return typeof expected === typeof actual;
}

type ConditionalResult = {
  result: boolean;
};

export function setConditionalResult<T1, T2>(
  operation: string,
  expected: T1,
  actual: T2,
  result: boolean
): ConditionalResult {
  return { result };
}

/** Performs the logical expression 'equals' between a given expected and actual value */
export function equals<T1, T2>(expected: T1, actual: T2): ConditionalResult {
  const [_expected, _actual] = computeArgumentTypes(expected, actual);
  const operation = "equals";

  // If the types of expected and actual are not the same
  if (!checkTypeEquality(_expected, _actual)) {
    return setConditionalResult(operation, expected, actual, false);
  }

  if (typeof _actual === "boolean" && typeof _expected === "boolean") {
    return setConditionalResult(operation, expected, actual, (_expected as boolean) === (_actual as boolean));
  }

  if (typeof _actual === "number" && typeof _expected === "number") {
    return setConditionalResult(operation, expected, actual, (_expected as number) === (_actual as number));
  }

  if (typeof _actual === "string" && typeof _expected === "string") {
    return setConditionalResult(
      operation,
      expected,
      actual,
      (removeWhitespace(_actual.toLowerCase()) as string) === (removeWhitespace(_expected.toLowerCase()) as string)
    );
  }

  return setConditionalResult(operation, expected, actual, _expected === _actual);
}

/** Performs the logical expression 'contains'  */
export function contains<T1, T2>(expected: T1, actual: T2): ConditionalResult {
  const [_expected, _actual] = computeArgumentTypes(expected, actual);
  const operation = "contains";

  // If _actual is an array and _expected is a string
  if (Array.isArray(_actual) && (typeof _expected === "string" || typeof _expected === "number")) {
    const hasMatch =
      // Loop over each value in _actual and check if it contains the expected string
      _actual.some((item) => {
        if ((typeof item === "string" && !isNaN(parseFloat(item))) || typeof item === "number") {
          return parseFloat(item as string) === parseFloat(_expected as string);
        } else if (typeof item === "string") {
          return removeWhitespace(item.toLowerCase()).includes(removeWhitespace((_expected as string).toLowerCase()));
        }
        return false;
      });

    return setConditionalResult(operation, expected, actual, hasMatch);
  }

  // If _actual is a string and _expected is an array
  if (Array.isArray(_expected) && (typeof _actual === "string" || typeof _actual === "number")) {
    const hasMatch =
      // Loop over each value in _expected and check if it contains the actual string
      _expected.some((item) => {
        if ((typeof item === "string" && !isNaN(parseFloat(item))) || typeof item === "number") {
          return parseFloat(item as string) === parseFloat(_actual as string);
        } else if (typeof item === "string") {
          return removeWhitespace(item.toLowerCase()).includes(removeWhitespace((_actual as string).toLowerCase()));
        }
        return false;
      });

    return setConditionalResult(operation, expected, actual, hasMatch);
  }

  // If _actual is a string and _expected is a string
  if (typeof _actual === "string" && typeof _expected === "string") {
    return setConditionalResult(
      // Check if the actual string contains the expected string
      operation,
      expected,
      actual,
      removeWhitespace(_actual.toLowerCase()).includes(removeWhitespace(_expected.toLowerCase()))
    );
  }

  // If the types of expected and actual are not the same
  if (!checkTypeEquality(_expected, _actual)) {
    return setConditionalResult(operation, expected, actual, false);
  }

  return setConditionalResult(operation, expected, actual, false);
}

/** Performs the logical expression 'doesNotContain' */
export function doesNotContain<T1, T2>(expected: T1, actual: T2): ConditionalResult {
  const operation = "doesNotContain";
  // Performs the logical expression 'contains' and inverts the result
  return setConditionalResult(operation, expected, actual, !contains(expected, actual).result);
}

/** Performs the logical expression 'greaterThan' */
export function greaterThan<T1, T2>(expected: T1, actual: T2): ConditionalResult {
  const operation = "greaterThan";
  const [_expected, _actual] = computeArgumentTypes(expected, actual);

  // If the types of expected and actual are not the same
  if (!checkTypeEquality(_expected, _actual)) {
    return setConditionalResult(operation, expected, actual, false);
  }

  if (typeof _actual === "number" && typeof _expected === "number") {
    return setConditionalResult(operation, expected, actual, (_actual as number) > (_expected as number));
  }

  if (_actual instanceof Date && _expected instanceof Date) {
    // Convert the dates to luxon dates
    const luxonActual = DateTime.fromJSDate(_actual);
    const luxonExpected = DateTime.fromJSDate(_expected);

    return setConditionalResult(
      operation,
      expected,
      actual,
      // Check if the actual date's unix timestamp is greater than the expected date's unix timestamp
      luxonActual.toMillis() > luxonExpected.toMillis()
    );
  }

  return setConditionalResult(operation, expected, actual, false);
}

/** Performs the logical expression 'lessThan' */
export function lessThan<T1, T2>(expected: T1, actual: T2): ConditionalResult {
  const operation = "lessThan";

  // If both numbers and the values are the same
  if (typeof expected === "number" && typeof actual === "number" && (actual as number) === (expected as number))
    return setConditionalResult(operation, expected, actual, false);

  // Perform the logical expression 'greaterThan' and invert the result
  return setConditionalResult(operation, expected, actual, !greaterThan(expected, actual).result);
}

/** Performs the logical expression 'exists' */
export function exists<T1, T2 extends any>(expected: T1, actual: T2): ConditionalResult {
  const operation = "exists";
  return setConditionalResult(operation, expected, actual, actual !== undefined && actual !== null && actual !== "");
}

/** Performs the logical expression 'doesNotExist' */
export function doesNotExist<T1, T2>(expected: T1, actual: T2): ConditionalResult {
  const operation = "doesNotExist";
  // Performs the logical expression 'exists' and inverts the result
  return setConditionalResult(operation, expected, actual, !exists(expected, actual).result);
}

/** Performs the logical expression 'doesNotEqual' */
export function doesNotEqual<T1, T2>(expected: T1, actual: T2): ConditionalResult {
  const operation = "doesNotEqual";
  // Perform the equals operation and invert the result
  return setConditionalResult(operation, expected, actual, !equals(expected, actual).result);
}

/** Computes the correct values by coercsing them to their correct types */
export function computeArgumentTypes<T1, T2>(expected: T1, actual: T2): [unknown, unknown] {
  const dateService = new DateService();

  if (actual instanceof Date || expected instanceof Date) {
    // If the expected and actual are both dates
    if (dateService.parseDate(expected) instanceof Date) {
      return [dateService.parseDate(expected), dateService.parseDate(actual)];
    }
  }

  if (typeof expected === "boolean" && typeof actual === "string") {
    const parsedActual = JSON.parse(actual) as boolean;
    return [expected, parsedActual];
  }

  if (typeof actual === "boolean" && typeof expected === "string") {
    const parsedExpected = JSON.parse(expected) as boolean;
    return [parsedExpected, actual];
  }

  // If expected is a string and can not be converted to a number then we can return string
  if (typeof expected === "string" && isNaN(parseFloat(expected))) {
    return [expected, actual];
  }

  // If the expected is a string and matches the regular expression for an ISO string
  if (
    (typeof expected === "string" && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/.test(expected)) ||
    (typeof actual === "string" && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/.test(actual))
  ) {
    return [dateService.parseDate(expected), dateService.parseDate(actual)];
  }

  // If expected is a string and can be converted to a number
  if (typeof expected === "string" && !isNaN(parseFloat(expected))) {
    // If expected is a string and can be converted to a number then we can return number
    if (typeof actual === "string") {
      return [parseFloat(expected), parseFloat(actual)];
    } else {
      return [parseFloat(expected), actual];
    }
  }

  // If expected is a number and actual is a string which can be converted to a number
  if (typeof expected === "number" && typeof actual === "string" && !isNaN(parseFloat(actual))) {
    return [expected, parseFloat(actual)];
  }

  // If actual is a number and expected is a string which can be converted to a number
  if (typeof actual === "number" && typeof expected === "string" && !isNaN(parseFloat(expected))) {
    return [parseFloat(expected), actual];
  }

  return [expected, actual];
}
